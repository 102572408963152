import { axiosClient } from "./apiClient";

export const fetchMeetings = async (accessToken, pageNumber, pageSize) => {
    const meetings = await axiosClient.get(`/zoom/fetch-meeting?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return meetings
}

export const fetchMeetingParticipant = async (meetingId, accessToken, pageNumber, pageSize) => {
    const participants = await axiosClient.get(`/zoom/fetch-participants/${meetingId}?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return participants;
}

export const fetchCapsuleCRMData = async (search, accessToken, { token }) => {
    const participants = await axiosClient.get(`/zoom/fetch-capsuleData?search=${search}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        cancelToken: token
    });
    return participants;
}

export const HandleLink = async (payLoad, accessToken) => {
    const response = await axiosClient.post('/zoom/update-meeting', payLoad, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    });
    return response
}