import React, { useState, useEffect } from "react";
import moment from "moment";

import List from "../List";
import TableActionCell from "../TableActionCell";
import { useAuth } from "../../../Hooks/useAuth";
import { notification } from "../../../service/index";
import Loader from "../../common/Loader";
import NoRecordFound from "../../common/NoRecordFound";
import PaginationComponent from "../../common/ReactPaginate";
import AbstractModal from "../../AbstractModal";
import { useDispatch } from "react-redux";
import { openModal } from "../../../reducers/modal";
import { fetchAllDealumMember } from "../../../API/fund";
import LinkMemberToCapsuleForm from "../../forms/LinkDealumMemberToCapsule/LinkMemberToCapsuleForm";

const DealumMemberList = () => {
  const [records, setRecords] = useState([]);
  const [Id, setId] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { token } = useAuth();

  const fetchDealumAllMember = () => {
    setIsLoading(true);
    fetchAllDealumMember(token, page, 20)
      .then(({ data, status }) => {
        if (status === 200 && data?.status) {
          setRecords(data?.rows);
          setCount(data?.count);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        notification({ title: "Error", message: err.message, type: "danger" });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDealumAllMember();
  }, [page]);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleClose = () => {
    setShow(!show);
    dispatch(openModal({ modalType: "" }));
  };
  const handleEdit = (id) => {
    setShow(!show);
    dispatch(openModal({ modalType: "edit" }));
    setId(id);
  };

  const columns = [
    {
      name: "First_Name",
      label: "FirstName",
      width: 100,
      align: "center",
    },
    {
      name: "Last_Name",
      label: "LastName",
      align: "center",
      width: 100,
    },
    {
      name: "Email",
      label: "Email",
      align: "center",
      width: 100,
    },
    {
      name: "capsuleUser",
      label: "capsuleUser",
      align: "center",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left",
    },
  ];

  const rows = (records || []).map((row) => {
    const action = (
      <TableActionCell
        actionsHeadline="Edit"
        hideEdit={false}
        editLabel="Edit"
        onEdit={handleEdit}
        hideDestroy={true}
        tableRow={{ rowId: row.id }}
      // onDestroy={handleDestroy}
      />
    );
    return {
      ...row,
      action,
    };
  });

  if (isLoading) {
    return <Loader />;
  } else if (!(records || []).length) {
    return <NoRecordFound />;
  }

  const handleSortingChange = () => {
    // console.log("Handle Sorting Change");
  };
  const handleSelect = () => { };

  return (
    <div>
      <List
        rows={rows}
        loading={isLoading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="DealumMember List"
        handleSelect={handleSelect}
        ReactPaginate={
          <PaginationComponent
            handlePageChange={handlePageChange}
            currentPage={page}
            pageCount={Math.ceil(count / 20)}
          />
        }
      />
      <AbstractModal
        show={show}
        handleClose={handleClose}
        editComponent={
          <LinkMemberToCapsuleForm
            partcipantInfo={records.find((item) => item.id === Id)}
            handleClose={handleClose}
            fetchDealumAllMember={fetchDealumAllMember}
          />
        }
      />
    </div>
  );
};

export default DealumMemberList;
