import React, { useState, useEffect } from "react";

import List from "../List";
import TableActionCell from "../TableActionCell";
import { fetchAllfundData } from "../../../API/fund";
import { useAuth } from "../../../Hooks/useAuth";
import { notification } from "../../../service/index";
import Loader from "../../common/Loader";
import NoRecordFound from "../../common/NoRecordFound";
import AddButton from "../../common/AddButton";
import PaginationComponent from "../../common/ReactPaginate";
import AbstractModal from "../../AbstractModal";
import { useDispatch } from "react-redux";
import { openModal } from "../../../reducers/modal";
import CreateFundForm from "../../forms/fundData/CreateFund";
import UpdateFundForm from "../../forms/fundData/UpdateFund";

const FundList = () => {
  const [records, setRecords] = useState([]);
  const [Id, setId] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { token } = useAuth();

  const fetchAllFund = () => {
    setIsLoading(true);
    fetchAllfundData(token, page, 10)
      .then(
        ({
          data: {
            status,
            fundData: { count, rows },
          },
        }) => {
          if (status) {
            setRecords(rows);
            setCount(count);
            setIsLoading(false);
          }
        }
      )
      .catch((err) => {
        notification({ title: "Error", message: err.message, type: "danger" });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAllFund();
  }, [page]);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleClose = () => {
    setShow(!show);
    dispatch(openModal({ modalType: "" }));
  };

  const handleEdit = (id) => {
    setShow(!show);
    dispatch(openModal({ modalType: "edit" }));
    setId(id);
  };

  // const handleDestroy = (id) => {
  //   deletePopup({
  //     title: "Confirm to Delete",
  //     message: "Are you sure to delete this one.",
  //     onConfirm: () => {
  //       deleteCompany(token, id)
  //         .then(({ status, data }) => {
  //           if (status === 200 && data?.status) {
  //             notification({
  //               title: "Deleted",
  //               type: "success",
  //               message: "Successfully deleted!",
  //             });
  //             if (count % 10 === 1 && count != 1) {
  //               setPage((page) => page - 1);
  //             } else {
  //               // fetchAllCompanies();
  //             }
  //           }
  //         })
  //         .catch((err) => {
  //           notification({
  //             title: "Error",
  //             type: "danger",
  //             message: err.message,
  //           });
  //         });
  //     },
  //     id,
  //   });
  // };

  const columns = [
    {
      name: "name",
      label: "FundName",
      width: 100,
      align: "left",
    },
    {
      name: "action",
      label: "",
      align: "right",
    },
  ];

  const rows = (records || []).map((row) => {
    const action = (
      <TableActionCell
        actionsHeadline="Edit"
        hideEdit={false}
        editLabel="Edit"
        onEdit={handleEdit}
        hideDestroy={true}
        tableRow={{ rowId: row.id }}
        // onDestroy={handleDestroy}
      />
    );
    return {
      ...row,
      action,
      exit: !row.exit ? "No" : "yes",
      company: row.company ? row.company : "",
    };
  });

  if (isLoading) {
    return <Loader />;
  } else if (!(records || []).length) {
    return <NoRecordFound />;
  }

  const handleSortingChange = () => {
    // console.log("Handle Sorting Change");
  };
  const handleSelect = () => {};

  return (
    <div>
      <List
        // initialOrder={order}
        // initialDir={dir}
        rows={rows}
        loading={isLoading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Fund List"
        handleSelect={handleSelect}
        AddButton={<AddButton show={show} setShow={setShow} />}
        ReactPaginate={
          <PaginationComponent
            handlePageChange={handlePageChange}
            currentPage={page}
            pageCount={Math.ceil(count / 10)}
          />
        }
      />
      <AbstractModal
        show={show}
        handleClose={handleClose}
        Component={
          <CreateFundForm
            handleClose={handleClose}
            fetchAllFund={fetchAllFund}
          />
        }
        editComponent={
          <UpdateFundForm
            id={Id}
            handleClose={handleClose}
            fetchAllFund={fetchAllFund}
          />
        }
      />
    </div>
  );
};

export default FundList;
