import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    user: null,
}

const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        doLogin: (state, action) => {
            state.token = action.payload.token
            state.user = action.payload.user
        },
        doLogout: (state) => {
            state = initialState
        }
    }
});

export const { doLogin, doLogout } = app.actions;

export default app.reducer;