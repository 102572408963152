import { configureStore } from '@reduxjs/toolkit';

import app from './reducers/app';
import modal from './reducers/modal';
import sidebar from './reducers/sidebar';

export const store = configureStore({
  reducer: {
    app,
    modal,
    sidebar
  }
})