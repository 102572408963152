import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";

import AddButton from "../../common/AddButton";
import { useAuth } from "../../../Hooks/useAuth";
import AbstractModal from "../../AbstractModal";
import CreateInvestmentForm from "../../forms/investmentData/CreateInvestmentForm";
import {
  getAllfundInvestmentData,
  deleteOneFactInvData,
} from "../../../API/fund";
import List from "../List";
import TableActionCell from "../TableActionCell";
import { notification } from "../../../service";
import UpdateInvestMentForm from "../../forms/investmentData/UpdateInvestMentData";
import { openModal } from "../../../reducers/modal";
import Loader from "../../common/Loader";
import NoRecordFound from "../../common/NoRecordFound";
import PaginationComponent from "../../common/ReactPaginate";
import { deletePopup } from "../../../service/index";

const FundInvestmentList = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const { token } = useAuth();
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(!show);
    dispatch(openModal({ modalType: "" }));
  };
  const fetchAllFundInvestmentData = () => {
    setIsLoading(true);
    getAllfundInvestmentData(token, page, 20)
      .then(({ data: { data, status, totalCount } }) => {
        if (data && status === true) {
          setRecords(data);
          setCount(totalCount);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        notification({ title: "Failed", type: "danger", message: err.message });
      });
  };
  useEffect(() => {
    fetchAllFundInvestmentData();
  }, [page]);

  const handleEdit = (id) => {
    setId(id);
    dispatch(openModal({ modalType: "edit" }));
    setShow(!show);
  };

  const handleSortingChange = () => { };
  const handleSelect = () => { };
  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleDestroy = (id) => {
    deletePopup({
      title: "Confirm to Delete",
      onConfirm: () => {
        setIsLoading(true);
        deleteOneFactInvData(id, token)
          .then(({ data }) => {
            if (data && data?.status === true) {
              if (count % 20 === 1 && count != 1) {
                setPage((page) => page - 1);
              } else {
                fetchAllFundInvestmentData();
              }
              notification({
                title: "Deleted",
                type: "success",
                message: data?.message,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            notification({
              title: "Failed",
              type: "danger",
              message: err.message,
            });
          });
      },
      id,
    });
  };

  const columns = [
    {
      name: "FirstName",
      label: "First Name",
      width: 100,
      align: "center",
    },
    {
      name: "LastName",
      label: "Last Name",
      align: "center",
      width: 100,
    },
    {
      name: "CompanyName",
      label: "CompanyName",
      align: "center",
      width: 100,
    },
    {
      name: "Amount",
      label: "Amount",
      align: "center",
      width: 100,
    },
    {
      name: "fullDate",
      label: "Date",
      align: "center",
      width: 100,
    },
    {
      name: "DealType",
      label: "DealType",
      align: "center",
      width: 100,
    },
    {
      name: "Industry",
      label: "Industry",
      align: "center",
      width: 100,
    },
    {
      name: "RoundType",
      label: "RoundType",
      align: "center",
      width: 100,
    },
    {
      name: "Sector",
      label: "Sector",
      align: "center",
      width: 100,
    },
    {
      name: "DealCatgType",
      label: "DealCatgType",
      align: "center",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "center",
    },
  ];

  const rows = (records || []).map((row) => {
    const action = (
      <TableActionCell
        actionsHeadline="Edit"
        hideEdit={false}
        editLabel="Edit"
        onEdit={handleEdit}
        hideDestroy={false}
        tableRow={{ rowId: row.id }}
        onDestroy={handleDestroy}
      />
    );
    return {
      ...row,
      fullDate: row?.fullDate
        ? moment(row?.fullDate).format("YYYY-MM-DD")
        : "-",
      action,
    };
  });
  if (isLoading) {
    return <Loader />;
  } else if (!(records || []).length) {
    return <NoRecordFound />;
  }

  return (
    <div>
      <List
        // initialOrder={order}
        // initialDir={dir}
        rows={rows}
        loading={isLoading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Investment Data List"
        handleSelect={handleSelect}
        AddButton={<AddButton show={show} setShow={setShow} />}
        ReactPaginate={
          <PaginationComponent
            handlePageChange={handlePageChange}
            currentPage={page}
            pageCount={Math.ceil(count / 20)}
          />
        }
      />
      <AbstractModal
        show={show}
        handleClose={handleClose}
        Component={
          <CreateInvestmentForm
            handleClose={handleClose}
            fetchAllFundInvestmentData={fetchAllFundInvestmentData}
            setDataLoading={setIsLoading}
          />
        }
        editComponent={
          <UpdateInvestMentForm
            id={id}
            handleClose={handleClose}
            fetchAllFundInvestmentData={fetchAllFundInvestmentData}
            setDataLoading={setIsLoading}
          />
        }
      />
    </div>
  );
};
export default FundInvestmentList;
