import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";

import TextField from "../common/TextField";
import SubmitButton from "../common/SubmitButtom";
import { forgetPassword } from "../../API/user";
import { notification, status } from "../../service";
import Loader from "../common/Loader";

const schema = yup
  .object({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
        "Please enter a valid email"
      )
      .required("Please Enter Email"),
  })
  .required();

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = (v) => {
    setIsLoading(true);
    forgetPassword(v)
      .then(({ status, data }) => {
        setIsLoading(false);
        if (status === 201 && data?.status) {
          notification({
            title: "Success",
            type: "success",
            message: data?.message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 404 && !err?.response?.data?.status) {
          notification({
            type: "danger",
            title: "User not found!",
            message: err?.response?.data?.message,
          });
        } else {
          notification({
            type: "danger",
            message: err.message,
            title: "Error",
          });
        }
      });
  };

  return (
    <div className="login-signup-bg" style={{ paddingTop: "50px" }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <div className="form-wrapper">
            <Typography
              variant="h4"
              style={{
                marginBottom: 10,
                textAlign: "center",
                color: "#1346a3",
              }}>
              Enter your email to get reset link
            </Typography>
            <form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
              <TextField
                label="Email"
                placeholder="Enter your email"
                customId="email"
                register={register}
                errors={errors?.email?.message}
              />
              <div>
                {isLoading ? (
                  <Loader height={52} />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Link to={"/login"}>Back to login page</Link>
                    <SubmitButton label="Get link" />
                  </div>
                )}
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgetPassword;
