import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import List from "../List";
import TableActionCell from "../TableActionCell";
import { fetchCompanies } from "../../../API/company";
import { useAuth } from "../../../Hooks/useAuth";
import { notification } from "../../../service/index";
import Loader from "../../common/Loader";
import NoRecordFound from "../../common/NoRecordFound";
import AddButton from "../../common/AddButton";
import PaginationComponent from "../../common/ReactPaginate";
import AbstractModal from "../../AbstractModal";
import CreateCompanyForm from "../../forms/company/CreateCompanyForm";
import { useDispatch } from "react-redux";
import { openModal } from "../../../reducers/modal";
import { deleteCompany } from "../../../API/company";
import UpdateCompanyForm from "../../forms/company/updateCompanyForm";
import { deletePopup } from "../../../service/index";

const CompanyList = () => {
  const [records, setRecords] = useState([]);
  const [Id, setId] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { token } = useAuth();
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const fetchAllCompanies = () => {
    setIsLoading(true);
    fetchCompanies(token, page, 10)
      .then(({ data: { status, totalCount, companies } }) => {
        if (status) {
          setRecords(companies);
          setCount(totalCount);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        notification({ title: "Error", message: err.message, type: "danger" });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAllCompanies();
  }, [page]);

  useEffect(() => {
    if (search === "?tab=add_new_company") {
      setShow(!show);
      dispatch(openModal({ modalType: "create" }));
    }
  }, [search]);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };
  const handleClose = () => {
    setShow(!show);
    dispatch(openModal({ modalType: "" }));
    if (search === "?tab=add_new_company") {
      navigate("/investment-list");
    }
  };
  const handleEdit = (id) => {
    setShow(!show);
    dispatch(openModal({ modalType: "edit" }));
    setId(id);
  };

  const handleDestroy = (id) => {
    deletePopup({
      title: "Confirm to Delete",
      onConfirm: () => {
        deleteCompany(token, id)
          .then(({ status, data }) => {
            if (status === 200 && data?.status) {
              notification({
                title: "Deleted",
                type: "success",
                message: "Successfully deleted!",
              });
              if (count % 10 === 1 && count !== 1) {
                setPage((page) => page - 1);
              } else {
                fetchAllCompanies();
              }
            }
          })
          .catch((err) => {
            notification({
              title: "Error",
              type: "danger",
              message: err.message,
            });
          });
      },
      id,
    });
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      width: 100,
      align: "center",
    },
    // {
    //   name: "exit",
    //   label: "Exit",
    //   align: "center",
    //   width: 100,
    // },
    {
      name: "ExitYr",
      label: "ExitYear",
      align: "center",
      width: 100,
    },
    {
      name: "sector",
      label: "Sector",
      align: "center",
      width: 100,
    },
    {
      name: "state",
      label: "State",
      align: "center",
      width: 100,
    },
    {
      name: "Industry",
      label: "Industry",
      align: "center",
      width: 100,
    },
    {
      name: "country",
      label: "Country",
      align: "center",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left",
    },
  ];

  const rows = (records || []).map((row) => {
    const action = (
      <TableActionCell
        actionsHeadline="Edit"
        hideEdit={false}
        editLabel="Edit"
        onEdit={handleEdit}
        hideDestroy={false}
        tableRow={{ rowId: row.id }}
        onDestroy={handleDestroy}
      />
    );
    return {
      ...row,
      action,
      company: row.company ? row.company : "",
      country: row.country ? row.country : "-",
    };
  });

  if (isLoading) {
    return <Loader />;
  } else if (!(records || []).length) {
    return <NoRecordFound />;
  }

  const handleSortingChange = () => {
    // console.log("Handle Sorting Change");
  };
  const handleSelect = () => {};

  return (
    <div>
      <List
        // initialOrder={order}
        // initialDir={dir}
        rows={rows}
        loading={isLoading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Company List"
        handleSelect={handleSelect}
        AddButton={<AddButton show={show} setShow={setShow} />}
        ReactPaginate={
          <PaginationComponent
            handlePageChange={handlePageChange}
            currentPage={page}
            pageCount={Math.ceil(count / 10)}
          />
        }
      />
      <AbstractModal
        show={show}
        handleClose={handleClose}
        Component={
          <CreateCompanyForm
            handleClose={handleClose}
            fetchAllCompanies={fetchAllCompanies}
          />
        }
        editComponent={
          <UpdateCompanyForm
            id={Id}
            handleClose={handleClose}
            fetchAllCompanies={fetchAllCompanies}
          />
        }
      />
    </div>
  );
};

export default CompanyList;
