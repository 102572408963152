import { Store } from 'react-notifications-component';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { confirmAlert } from "react-confirm-alert";


export const notification = ({ title = '', message, type = 'success' }) => {
    Store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

// hex to rgba converter
export const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const themes = {
    light: {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489',
        },
        menu: {
            menuContent: '#fbfcfd',
            icon: '#0098e5',
            hover: {
                backgroundColor: '#c5e4ff',
                color: '#44596e',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    },
    dark: {
        sidebar: {
            backgroundColor: '#0b2948',
            color: '#8ba1b7',
        },
        menu: {
            menuContent: '#082440',
            icon: '#59d0ff',
            hover: {
                backgroundColor: '#00458b',
                color: '#b6c8d9',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
};

export const download = (id) => {
    const token = localStorage.getItem('token');
    axios({
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/image/download/${id}`,
        method: "GET",
        responseType: "blob",
        headers: {
            "authorization": token ? `Bearer ${token}` : ''
        }
    }).then((res) => {
        FileDownload(res.data, 'qrcode.png');
    }).catch((err) => {
        if (err.response.status === 404) {
            notification({ message: "No qr code found", title: "Not Found", type: "danger" });
            return;
        } else if (err.response.status === 401) {
            notification({ message: "Permission denied!", title: "Unauthorize", type: "danger" });
            return;
        }
        notification({ message: err.message, title: "Axios Error", type: "danger" })
    })
}



export const deletePopup = ({ title, message, onConfirm, id }) => {
    confirmAlert({
        title: title || "Confirm to Delete",
        message: "Are you sure you want to delete this?.",
        buttons: [
            {
                label: "Yes",
                onClick: () => onConfirm(id),
            },
            {
                label: "No",
            },
        ],
    });
}


export const status = [{
    label: "Not Started",
    value: "not_started"
},
{
    label: "In Progress",
    value: "in_progress"
},
{
    label: "Completed",
    value: "completed"
},
{
    label: "On Hold",
    value: "on_hold"
}];

export const priority = [{
    label: "Low",
    value: "low"
},
{
    label: "Medium",
    value: "medium"
},
{
    label: "High",
    value: "high"
},
{
    label: "Critical",
    value: "critical"
}]

export const dataDimDealType2 = [
    {
        id: 1,
        name: "New"
    },
    {
        id: 2,
        name: "FollowOn"
    }
]

export const dataDimDealType = [
    {
        id: 1,
        name: "New",
    },
    {
        id: 2,
        name: "FollowOn",
    },
    {
        id: 3,
        name: "SubsequentClose",
    }
]

// props for preventing default value change input type text

export const preventDefaultProps = {
    step: 'any',
    onWheel: (e) => { e.target.blur() },
    onKeyDown: (e) => {
        if (e.which === 38 || e.which === 40) {
            e.preventDefault();
        }
    }
}