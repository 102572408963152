import { useSelector } from 'react-redux';

export const useUserPermission = () => {
    const { user } = useSelector((state) => state.app);
    let permissions = {
        isSuperAdmin: false, isSystemAdmin: false, isUserCreate: false,
        isRoleCreate: false, isRoleUpdate: false, isRoleDelete: false, isUserUpdate: false, isUserDelete: false,isSuper:false
    };
    if (user) {
        const { role, permission,isSuper } = user;
        permissions.isSuper=isSuper;
        if (role == 'superadmin') {
            permissions.isSuperAdmin = true
        }
        if (role == 'systemadmin') {
            permissions.isSystemAdmin = true
        }
        if (permission) {
            const { rolePermission, userPermission } = permission
            if (rolePermission.includes('C')) {
                permissions.isRoleCreate = true
            }
            if (rolePermission.includes('U')) {
                permissions.isRoleUpdate = true
            }
            if (rolePermission.includes('D')) {
                permissions.isRoleDelete = true
            }
            if (userPermission.includes('C')) {
                permissions.isUserCreate = true
            }
            if (userPermission.includes('U')) {
                permissions.isUserUpdate = true
            }
            if (userPermission.includes('D')) {
                permissions.isUserDelete = true
            }
        }
    }
    return permissions;
}   
