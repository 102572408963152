import React, { useState, useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import {
//   FormControl,
//   FormControlLabel,
//   RadioGroup,
//   FormLabel,
//   Radio,
// } from "@mui/material";

import TextField from "../../common/TextField";
import Loader from "../../common/Loader";
import SubmitButton from "../../common/SubmitButtom";
import SelectField from "../../common/SelectField";
import { notification } from "../../../service/index";
import { useAuth } from "../../../Hooks/useAuth";
import {
  fetchSectorState,
  findCompanyById,
  updateCompany,
} from "../../../API/company";
import MultipleSelect from "../../select/MutliSelectComponent";

const schema = yup.object({
  name: yup.string().required("required"),
  // exit: yup.boolean().required("required"),
  ExitYr: yup.mixed().required("required"),
  sector: yup.string().required("required"),
  state: yup.string().required("required"),
  // country: yup.string().required("required"),
  industries: yup.string().nullable(),
});

const UpdateCompanyForm = ({ id, handleClose, fetchAllCompanies }) => {
  const { token } = useAuth();
  const [stateSectorIndustries, setstateSectorIndustries] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [industries, setIndustries] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  // const [exit, setExit] = useState(getValues("exit"));

  useEffect(() => {
    fetchSectorState(token)
      .then(({ data, status }) => {
        if (data?.status && status === 200) {
          setstateSectorIndustries(data?.result);
        }
      })
      .catch((err) => {
        notification({ title: "Error", type: "success", message: err.message });
      });
  }, []);

  useEffect(() => {
    findCompanyById(token, id)
      .then(({ data, status }) => {
        if (data?.status && status === 200) {
          setValue("name", data?.data?.name);
          setValue("ExitYr", data?.data?.ExitYr ? data?.data?.ExitYr : "N/A");
          // setValue("exit", !data?.data["exit"] ? false : true);
          setValue("sector", data?.data?.sector ? data?.data?.sector : "N/A");
          setValue("state", data?.data?.state ? data?.data?.state : "N/A");
          setValue("industries", data?.data?.Industries || "N/A");
          // setValue("country", data?.data?.country || "N/A");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        notification({ type: "danger", title: "Error", message: err.message });
        setIsLoading(false);
      });
  }, []);

  const onFormSubmit = (v) => {
    const payload = {
      ...v,
      sector: v.sector === "N/A" ? null : v.sector,
      state: v.state === "N/A" ? null : v.state,
      ExitYr: v.ExitYr === "N/A" ? null : v.ExitYr,
      // country: v.country === "N/A" ? null : parseInt(v.country),
      country: "237",
      industries: industries,
      exit: false,
    };
    updateCompany(token, payload, id)
      .then(({ status, data }) => {
        if (status === 201 && data?.status) {
          handleClose();
          fetchAllCompanies();
          notification({
            title: "Success",
            type: "success",
            message: "successfully upated!",
          });
        }
      })
      .catch((err) => {
        handleClose();
        notification({ type: "danger", title: "Error", message: err.message });
      });
  };

  const getYear = (from) => {
    let year = [];
    for (let i = new Date().getFullYear(); i >= from; i--) {
      year.push(i);
    }
    return year;
  };

  // const handleRadioChange = (e) => {
  //   setExit((pre) => !pre);
  // };

  return isLoading ? (
    <Loader height={"50vh"} />
  ) : (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Update Company
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <TextField
              label="Company Name"
              placeholder="Enter Company Name"
              customId="name"
              type="text"
              register={register}
              errors={errors?.name?.message}
            />
          </div>
          {/* <div className="col-md-6">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Exit
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={getValues("exit")}
                onChange={handleRadioChange}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                  id="exit"
                  {...register("exit")}
                />
                <FormControlLabel
                  defaultChecked
                  value={false}
                  control={<Radio />}
                  label="No"
                  id="exit"
                  {...register("exit")}
                />
              </RadioGroup>
              {errors?.exit?.message && (
                <p style={{ color: "red" }}>{errors?.exit?.message}</p>
              )}
            </FormControl>
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-6 mt-2">
            <SelectField
              componentId={"state"}
              register={register}
              label={"State"}
              errors={errors?.state?.message}
              records={stateSectorIndustries?.state}
              initValue={getValues("state")}
            />
          </div>
          <div className="col-md-6 mt-2">
            <SelectField
              componentId={"sector"}
              register={register}
              label={"Sector"}
              errors={errors?.sector?.message}
              records={stateSectorIndustries?.sector}
              initValue={getValues("sector")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4">
            <MultipleSelect
              records={stateSectorIndustries?.industry}
              initValue={getValues("industries")}
              setIndustries={setIndustries}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <SelectField
              componentId={"ExitYr"}
              register={register}
              label={"Exit Year"}
              errors={errors?.ExitYr?.message}
              records={getYear(1950)}
              initValue={getValues("ExitYr")}
            />
          </div>
          {/* <div className="col-md-6 mt-3">
            <SelectField
              componentId={"country"}
              register={register}
              label={"Country"}
              errors={errors?.country?.message}
              records={stateSectorIndustries?.countries}
              initValue={getValues("country")}
            />
          </div> */}
        </div>
        <SubmitButton label="update Company" />
      </form>
    </div>
  );
};

export default UpdateCompanyForm;
