import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useDebounce } from 'use-debounce';

import { fetchSimiliarFunds } from "../../API/fund";
import { useAuth } from "../../Hooks/useAuth";
import { notification } from "../../service/index";

const AutoCompleteFund = ({ selectFund, initValue }) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 200);
  const [records, setRecords] = useState([]);
  const [value, setValue] = React.useState(initValue || null);
  const { token } = useAuth();

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (debouncedSearch) {
      fetchSimiliarFunds(token, debouncedSearch, source)
        .then((res) => {
          if (res?.status === 200 && res?.data?.status) {
            setRecords(res?.data?.rows);
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            return;
          }
          setRecords([]);
          notification({ title: "Error", type: "danger", message: err.message });
        });
    } else {
      setRecords([]);
    }
    return () => {
      source.cancel('request canceled');
    }
  }, [debouncedSearch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    selectFund(records.find((item) => item.FundName === newValue)?.id);
  };
  return (
    <div>
      <Autocomplete
        value={value}
        onChange={handleChange}
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
          if (value) {
            setValue(null);
          }
          if (event) {
            selectFund(null);
          }
        }}
        freeSolo
        id="free-solo-demo"
        options={records.map((item) => item.FundName)}
        sx={{ width: "100%", marginTop: "10px" }}
        size="small"
        renderInput={(params) => <TextField {...params} label="Funds" />}
      />
    </div>
  );
};

export default AutoCompleteFund;
