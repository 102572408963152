import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../common/Loader";

import TextField from "../common/TextField";
import SubmitButton from "../common/SubmitButtom";
import { notification } from "../../service";
import SelectField from "../common/SelectField";
import { openModal } from "../../reducers/modal";
import { useUserPermission } from "../../Hooks/useUserPermission";
import CustomLink from "../common/CustomLink";
import { signUpUser } from "../../API/user";
import { doLogin } from "../../reducers/app";

const schema = yup
  .object({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
        "Please enter a valid email"
      )
      .required("Please Enter email"),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email"), null], "Emails must match")
      .required("please enter your confirm email"),
    username: yup
      .string()
      .required("enter username")
      .matches(
        /^[a-zA-Z]([a-zA-Z0-9]*_?[a-zA-Z0-9]+){2,9}$/,
        "Username must start with a letter and be between 3 and 10 characters"
      ),
    password: yup
      .string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords don't match."),
  })
  .required();

const SignUp = ({ handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { modalData } = useSelector((state) => state.modal);
  const [select, setSelect] = useState(modalData);
  const [show, setShowModal] = useState(false);
  const [dob, setDob] = useState();
  const [joiningDate, setJoiningDate] = useState(new Date());
  const { isRoleCreate, isSuperAdmin, isSystemAdmin, isUserCreate } =
    useUserPermission();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const onFormSubmit = (v) => {
    setIsLoading(true);
    delete v.confirmPassword;
    signUpUser(v)
      .then(({ data }) => {
        if (data?.result?.status === 200) {
          setIsLoading(false);
          const {
            result: { user },
            token,
          } = data;
          if (token && user) {
            notification({
              title: "SignUp Success",
              message: "SignUp Successfully!",
            });
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            dispatch(doLogin({ token, user }));
            navigate("/dashboard");
          }
        }
      })
      .catch(({ response: { data } }) => {
        setIsLoading(false);
        notification({
          type: "danger",
          message: data?.message,
          title: "Error!",
        });
      });
  };

  return (
    <div className="login-signup-bg">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <div className="form-wrapper">
            <Typography
              variant="h4"
              style={{
                marginBottom: 10,
                textAlign: "center",
                color: "#1346a3",
              }}>
              Register
            </Typography>
            <form className="signup-form" onSubmit={handleSubmit(onFormSubmit)}>
              <TextField
                label="Email"
                placeholder="Enter your email"
                customId="email"
                type="text"
                register={register}
                errors={errors?.email?.message}
              />
              <TextField
                label="Confirm Email"
                placeholder="Enter your confirm email"
                customId="confirmEmail"
                type="text"
                register={register}
                errors={errors?.confirmEmail?.message}
              />
              <TextField
                label="Username"
                placeholder="Enter your username"
                customId="username"
                type="text"
                register={register}
                errors={errors?.username?.message}
              />
              <TextField
                label="Password"
                placeholder="Enter Password"
                customId="password"
                type="password"
                register={register}
                errors={errors?.password?.message}
              />
              <TextField
                label="Confirm Password"
                placeholder="Enter Confirm Password"
                customId="confirmPassword"
                type="password"
                register={register}
                errors={errors?.confirmPassword?.message}
              />
              <CustomLink
                link={"/login"}
                text="Already have an Account?"
                label={"Login"}
              />
              <div>
                {isLoading ? (
                  <Loader height={52} />
                ) : (
                  <SubmitButton label={"Submit"} />
                )}
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
