import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Typography, Badge, Switch } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Home,
  Storage,
  Groups,
  CorporateFare,
  FoodBank,
  PeopleAlt,
  AddLink,
} from "@mui/icons-material";

import { SidebarHeader } from "./sidebar/SideBarHeader";
import Header from "./Header";
import { hexToRgba, themes } from "../service";
import { setCollapse } from "../reducers/sidebar";
import { useUserPermission } from "../Hooks/useUserPermission";
import { FundIcon } from '../icons';

const routes = [
  {
    link: "/investment-list",
    icon: <Storage />,
    label: "Investment Data",
  },
  {
    link: "/meeting-list",
    icon: <Groups />,
    label: "MeetingList",
  },
  {
    link: "/manage-companies",
    icon: <CorporateFare />,
    label: "Manage Companies",
  },
  {
    link: "/manage-funds",
    icon: <FundIcon />,
    label: "Manage Funds",
  },
  {
    link: "/manage-fund-members",
    icon: <PeopleAlt />,
    label: "Manage Member Funds",
  },
  {
    link: "/link-dealumMembers",
    icon: <AddLink />,
    label: "Link DealumMembers",
  },
  ,
  {
    link: "/dashboard",
    icon: <Home />,
    label: "Dealum Data",
  },
];

const SidebarPage = ({ compoment }) => {
  const { isSuperAdmin } = useUserPermission();
  const [theme, setTheme] = useState("light");
  const { collapsed } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggled, setToggled] = React.useState(false);
  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.ps-disabled`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, 1)
          : "transparent",
    }),
    button: {
      [`&.ps-disabled`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
      }}>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
          height: "100vh",
          overflowY: "auto",
          width: "20%",
        }}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <SidebarHeader
            style={{ marginBottom: "24px", marginTop: "16px" }}
            theme={theme}
            setTheme={setTheme}
            collapsed={collapsed}
          />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  opacity: collapsed ? 0 : 0.7,
                  letterSpacing: "0.5px",
                }}>
                General
              </Typography>
            </div>
            <Menu menuItemStyles={menuItemStyles}>
              {routes.map((route, key) =>
                route.subLink ? (
                  <SubMenu
                    label={route.label}
                    icon={route.icon}
                    suffix={route.suffix || null}>
                    {route.subLink.map((subRoute, key) => (
                      <MenuItem
                        active={location.pathname.includes(subRoute.link)}
                        key={key}
                        component={<Link to={subRoute.link} />}>
                        {subRoute.label}
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem
                    active={location.pathname.includes(route.link)}
                    key={key}
                    component={<Link to={route.link} />}
                    icon={route.icon}
                  >
                    {route.label}
                  </MenuItem>
                )
              )}
              {/* <SubMenu label="Maps" icon={<Language />}>
                <MenuItem> Google maps</MenuItem>
                <MenuItem> Open street maps</MenuItem>
              </SubMenu>
              <SubMenu label="Theme" icon={<RamenDining />}>
                <MenuItem> Dark</MenuItem>
                <MenuItem> Light</MenuItem>
              </SubMenu> */}
              {/* <SubMenu label="Components" icon={<Diamond />}>
                <MenuItem> Grid</MenuItem>
                <MenuItem> Layout</MenuItem>
                <SubMenu label="Forms">
                  <MenuItem> Input</MenuItem>
                  <MenuItem> Select</MenuItem>
                  <SubMenu label="More">
                    <MenuItem> CheckBox</MenuItem>
                    <MenuItem> Radio</MenuItem>
                  </SubMenu>
                </SubMenu>
              </SubMenu> */}
              {/* <SubMenu label="E-commerce" icon={<ShoppingCart />}>
                <MenuItem> Product</MenuItem>
                <MenuItem> Orders</MenuItem>
                <MenuItem> Credit card</MenuItem>
              </SubMenu> */}
            </Menu>

            {/* <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "32px",
              }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  opacity: collapsed ? 0 : 0.7,
                  letterSpacing: "0.5px",
                }}>
                Extra
              </Typography>
            </div> */}

            {/* <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={<CalendarMonth />}
                suffix={<Badge variant="success">New</Badge>}>
                Calendar
              </MenuItem>
              <MenuItem icon={<Book />}>Documentation</MenuItem>
              <MenuItem disabled icon={<HeartBroken />}>
                Examples
              </MenuItem>
            </Menu> */}
          </div>
          <div
            style={{
              paddingTop: 20,
              display: "flex",
              flexDirection: collapsed ? "column" : "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}>
            {!collapsed && <Typography>Collapse</Typography>}
            <Switch
              id="collapse"
              checked={collapsed}
              onChange={() => dispatch(setCollapse({ collapsed: !collapsed }))}
              label="Collapse"
            />
          </div>
        </div>
      </Sidebar>
      <div
        style={{
          width: collapsed ? "94%" : "80%",
          transition: "0.3s",
          transitionTimingFunction: "ease-out",
          backgroundColor: "#f8f9fc",
        }}>
        <Header />
        <div className="content-container">{compoment}</div>
      </div>
    </div>
  );
};

export default SidebarPage;
