import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    collapsed: true
}

const sidebar = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setCollapse: (state, action) => {
            state.collapsed = action.payload.collapsed
        }
    }
});

export const { setCollapse } = sidebar.actions;

export default sidebar.reducer;