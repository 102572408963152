import React from "react";

const NoRecordFound = ({ message }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <p style={{ textAlign: "center" }}>No record found</p>
    </div>
  );
};

export default NoRecordFound;
