import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import { CreateFundData } from "../../../API/fund";
import { notification } from "../../../service/index";
import { useAuth } from "../../../Hooks/useAuth";

const schema = yup.object({
  FundName: yup.string().required("required"),
});

const CreateFundForm = ({ handleClose, fetchAllFund }) => {
  const { token } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = (v) => {
    CreateFundData(token, v)
      .then(({ status, data }) => {
        if (status === 201 && data?.status) {
          handleClose();
          fetchAllFund();
          notification({
            title: "Created",
            type: "success",
            message: "fund successfully created!",
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 409 && !err?.response?.data?.status) {
          notification({
            title: "Duplicate!",
            type: "warning",
            message: err?.response?.data?.message,
          });
        } else {
          notification({
            title: "Error",
            type: "danger",
            message: err.message,
          });
          handleClose();
        }
      });
  };

  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Create Fund
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <TextField
              label="Fund Name"
              placeholder="Enter Fund Name"
              customId="FundName"
              type="text"
              register={register}
              errors={errors?.FundName?.message}
            />
          </div>
        </div>
        <SubmitButton label="Create Fund" />
      </form>
    </div>
  );
};

export default CreateFundForm;
