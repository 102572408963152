import { axiosClient } from './apiClient';
export const signInUser = async (payload) => {
    const response = await axiosClient.post('/user/login', payload, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });
    return response;
}
export const signUpUser = async (payload) => {
    const response = await axiosClient.post('/user/signup', payload, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });
    return response;
}

export const logOutUser = async (accessToken) => {
    const response = await axiosClient.post('/user/logout', {}, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

// function for making request for forget password
export const forgetPassword = async (payload) => {
    const response = await axiosClient.post('/user/forgetPass', payload, {
        headers: {
            "Content-Type": "application/json"
        }
    });
    return response;
}

// function for making request for reset password
export const resetPassword = async (resetToken, payload) => {
    const response = await axiosClient.post(`/user/resetPass/${resetToken}`, payload, {
        headers: {
            "Content-Type": "application/json"
        }
    });
    return response;
}
