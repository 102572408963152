import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextField from "../../common/TextField";
import SubmitButton from "../../common/SubmitButtom";
import { getFundById, UpdateFundData } from "../../../API/fund";
import { notification } from "../../../service/index";
import { useAuth } from "../../../Hooks/useAuth";
import Loader from '../../common/Loader';

const schema = yup.object({
  FundName: yup.string().required("required"),
});

const UpdateFundForm = ({ handleClose, fetchAllFund, id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = (v) => {
    setIsLoading(true);
    UpdateFundData(token, v, id)
      .then(({ status, data }) => {
        handleClose();
        if (status === 201 && data?.status) {
          fetchAllFund();
          notification({
            title: "Updated",
            type: "success",
            message: "fund successfully updated!",
          });
        }
      })
      .catch((err) => {
        handleClose();
        notification({
          title: "Error",
          type: "danger",
          message: err.message,
        });
      });
  };

  useEffect(() => {
    getFundById(token, id)
      .then(({ status, data }) => {
        if (status === 200 && data.status) {
          setIsLoading(false);
          setValue("FundName", data?.fundResult?.name);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        notification({ type: "danger", title: "Error", message: err.message });
      });
  }, []);

  return isLoading ? (<Loader height={170} />) : (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Update Fund
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <TextField
              label="Fund Name"
              placeholder="Enter Fund Name"
              customId="FundName"
              type="text"
              register={register}
              errors={errors?.FundName?.message}
            />
          </div>
        </div>
        <SubmitButton label="Update Fund" />
      </form>
    </div>
  );
};

export default UpdateFundForm;
