import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import {
//   FormControl,
//   FormControlLabel,
//   RadioGroup,
//   FormLabel,
//   Radio,
// } from "@mui/material";

import TextField from "../../common/TextField";
import Loader from "../../common/Loader";
import SubmitButton from "../../common/SubmitButtom";
import SelectField from "../../common/SelectField";
// import { getFundData } from "../../../API/fund";
import { notification } from "../../../service/index";
import { useAuth } from "../../../Hooks/useAuth";
// import SelectComponent from "../../select/SelectComponent";
import { fetchSectorState, createCompany } from "../../../API/company";
import MultipleSelect from "../../select/MutliSelectComponent";

const schema = yup.object({
  name: yup.string().required("required"),
  // exit: yup.boolean().required("required"),
  ExitYr: yup.string().required(),
  sector: yup.string().required("required"),
  state: yup.string().required("required"),
  // country: yup.string().required("required"),
});

const CreateCompanyForm = ({ handleClose, fetchAllCompanies }) => {
  const { token } = useAuth();
  const [stateSectorIndustry, setStateSectorIndustry] = useState(null);
  // let [records, setRecords] = useState(null);
  // const [company, selectCompany] = useState(null);
  const [industries, setIndustries] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    // control,
    // getValues,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    fetchSectorState(token)
      .then(({ data, status }) => {
        if (data?.status && status === 200) {
          setStateSectorIndustry(data?.result);
        }
      })
      .catch((err) => {
        notification({ title: "Error", type: "danger", message: err.message });
      });
  }, []);

  const getYear = (from) => {
    let year = [];
    for (let i = new Date().getFullYear(); i >= from; i--) {
      year.push(i);
    }
    return year;
  };

  const onFormSubmit = (v) => {
    const payload = {
      ...v,
      state: v.state === "N/A" ? null : v.state,
      sector: v.sector === "N/A" ? null : v.sector,
      ExitYr: v.ExitYr === "N/A" ? null : parseInt(v.ExitYr),
      // country: v.country === "N/A" ? null : parseInt(v.country),
      country: "237",
      industries,
      exit: false,
    };
    createCompany(token, payload)
      .then(({ status, data }) => {
        if (status === 201 && data?.status) {
          handleClose();
          fetchAllCompanies();
          notification({
            title: "Created",
            type: "success",
            message: "Company successfully created",
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 409 && !err?.response?.data?.status) {
          notification({
            title: "Duplicate!",
            type: "warning",
            message: err?.response?.data?.message,
          });
        } else {
          notification({
            title: "Error",
            type: "danger",
            message: err.message,
          });
          handleClose();
        }
      });
  };

  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Create Company
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <TextField
              label="Company Name"
              placeholder="Enter Company Name"
              customId="name"
              type="text"
              register={register}
              errors={errors?.name?.message}
            />
          </div>

          {/* <div className="col-md-6">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Exit
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                  id="exit"
                  {...register("exit")}
                />
                <FormControlLabel
                  defaultChecked
                  value={false}
                  control={<Radio />}
                  label="No"
                  id="exit"
                  {...register("exit")}
                />
              </RadioGroup>
              {errors?.exit?.message && (
                <p style={{ color: "red" }}>{errors?.exit?.message}</p>
              )}
            </FormControl>
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-6 mt-2">
            <SelectField
              componentId={"state"}
              register={register}
              label={"State"}
              errors={errors?.state?.message}
              records={stateSectorIndustry?.state}
            />
          </div>
          <div className="col-md-6 mt-2">
            <SelectField
              componentId={"sector"}
              register={register}
              label={"Sector"}
              errors={errors?.sector?.message}
              records={stateSectorIndustry?.sector}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4">
            <MultipleSelect
              records={stateSectorIndustry?.industry}
              initValue={"N/A"}
              setIndustries={setIndustries}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2">
            <SelectField
              componentId={"ExitYr"}
              register={register}
              label={"Exit Year"}
              errors={errors?.ExitYr?.message}
              records={getYear(1950)}
              initValue={"N/A"}
            />
          </div>
          {/* <div className="col-md-6 mt-2">
            <SelectField
              componentId={"country"}
              register={register}
              label={"Country"}
              errors={errors?.country?.message}
              records={stateSectorIndustry?.countries}
              initValue={"N/A"}
            />
          </div> */}
        </div>
        <SubmitButton label="Create Company" />
      </form>
    </div>
  );
};

export default CreateCompanyForm;
