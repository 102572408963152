import React from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "./SideBar";

const PrivateRoute = ({ auth, Component }) => {
    return (
        <>
            {
                auth ? <Sidebar compoment={Component}/>
                    : <Navigate to={'/login'} />
            }
        </>
    )
}

export default PrivateRoute;