import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

//Forms
import Login from "./components/forms/Login";
import SignUp from "./components/forms/SignUp";
import ForgetPassword from "./components/forms/ForgetPassword";
import ResetPassword from "./components/forms/ResetPassword";

import HomePage from "./components/HomePage";
import PrivateRoute from "./components/PrivateRoutes";

import Dashboard from "./pages/Dashboard";
import PowerBIReports from "./pages/PowerBIReports";

//list import
import FundInvestmentList from "./components/lists/InvestmentList/FundInvestmentList";
import MeetingList from "./components/lists/meetingsList/MeetingList";
import ParticipantList from "./components/lists/meetingsList/ParticipantList";
import CompanyList from "./components/lists/companyList/CompanyList";
import FundList from "./components/lists/fundList/FundList";
import FundMemberList from "./components/lists/memberList/FundMemberList";
import DealumMemberList from "./components/lists/memberList/DealumMemberList";

const Routers = ({ auth }) => {
  return (
    <Router>
      <Routes>
        <Route
          path="/investment-list"
          element={<PrivateRoute auth={auth} Component={<HomePage />} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute auth={auth} Component={<Dashboard />} />}
        />
        <Route
          path="/manage-companies"
          element={<PrivateRoute auth={auth} Component={<CompanyList />} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgetPass" element={<ForgetPassword />} />
        <Route path="/resetPass" element={<ResetPassword />} />
        {/* <Route
          path="/powerbi-report"
          element={<PrivateRoute auth={auth} Component={<PowerBIReports />} />}
        /> */}
        <Route
          path="/"
          element={
            <PrivateRoute auth={auth} Component={<FundInvestmentList />} />
          }
        />
        <Route
          path="/meeting-list"
          element={<PrivateRoute auth={auth} Component={<MeetingList />} />}
        />
        <Route
          path="/participant-list/:id"
          element={<PrivateRoute auth={auth} Component={<ParticipantList />} />}
        />
        <Route
          path="/manage-funds"
          element={<PrivateRoute auth={auth} Component={<FundList />} />}
        />
        <Route
          path="/manage-fund-members"
          element={<PrivateRoute auth={auth} Component={<FundMemberList />} />}
        />
        <Route
          path="/link-dealumMembers"
          element={
            <PrivateRoute auth={auth} Component={<DealumMemberList />} />
          }
        />
      </Routes>
    </Router>
  );
};

export default Routers;
