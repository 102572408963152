import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { fetchMeetings } from "../../../API/zoom";
import { useAuth } from "../../../Hooks/useAuth";
import { notification } from "../../../service/index";
import List from "../List";
import TableActionCell from "../TableActionCell";
import Loader from "../../common/Loader";
import NoRecordFound from "../../common/NoRecordFound";
import PaginationComponent from "../../common/ReactPaginate";

const MeetingList = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const { token } = useAuth();
  const navigate = useNavigate();
  const [meetingList, setMeetingList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchMeetings(token, page, 20)
      .then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          setMeetingList(res?.data?.meetings);
          setCount(res?.data?.totalCount);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          notification({
            type: "danger",
            title: err?.code,
            message: err?.response?.data?.message,
          });
        }
        notification({
          type: "danger",
          title: err?.code,
          message: err?.message,
        });
        setIsLoading(false);
      });
  }, [page]);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSelect = (row) => {
    navigate(`/participant-list/${row.id}`);
  };

  const handleSortingChange = () => {};

  const columns = [
    {
      name: "meeting_id",
      label: "Meeting ID",
      width: 100,
      align: "left",
    },
    {
      name: "meeting_topic",
      label: "Meeting Topic",
      align: "center",
      width: 100,
    },
    {
      name: "status",
      label: "Meeting Status",
      align: "center",
      width: 100,
    },
    {
      name: "created_time",
      label: "Create At",
      align: "center",
      width: 100,
    },
    {
      name: "duration",
      label: "Duration",
      align: "center",
      width: 100,
    },
    {
      name: "host_name",
      label: "Meeting Host",
      align: "center",
      width: 100,
    },
    {
      name: "host_email",
      label: "Host Email",
      align: "center",
      width: 100,
    },
  ];

  const rows = (meetingList || []).map((row) => {
    const action = (
      <TableActionCell
        actionsHeadline="Edit"
        hideEdit={true}
        editLabel="Edit"
        // onEdit={handleEdit}
        hideDestroy={true}
        tableRow={{ rowId: row.id }}
        // onDestroy={handleDestroy}
      />
    );
    return {
      ...row,
      created_time: moment(row?.created_time).format("YYYY-MM-DD HH:MM:SS"),
    };
  });

  if (isLoading) {
    return <Loader />;
  } else if (!(meetingList || []).length) {
    return <NoRecordFound />;
  }

  return (
    <div>
      <List
        // initialOrder={order}
        // initialDir={dir}
        rows={rows}
        loading={isLoading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Meetings"
        listBodyheight={"70vh"}
        handleSelect={handleSelect}
        ReactPaginate={
          <PaginationComponent
            currentPage={page}
            handlePageChange={handlePageChange}
            pageCount={Math.ceil(count / 20)}
          />
        }
      />
    </div>
  );
};

export default MeetingList;
