import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";

import TextField from "../../common/TextField";
import Loader from "../../common/Loader";
import SubmitButton from "../../common/SubmitButtom";
import {
  fetchSingleFundWithMember,
  updateFunMemberData,
} from "../../../API/fund";
import { notification } from "../../../service/index";
import { useAuth } from "../../../Hooks/useAuth";
import AutoCompleteMember from "../../select/AutoCompleteMember";
import AutoCompleteFund from "../../select/AutoCompleteFund";
import { CalIcon } from "../../../icons";

const schema = yup
  .object({
    date: yup.date().required("required"),
    amount: yup.string().required("required"),
    fund: yup.string().required("required"),
    member: yup.string().required("Member Name is required!"),
  })
  .required();

const UpdateFundMemberForm = ({ handleClose, fetchAllMemberWithFund, id }) => {
  const { token } = useAuth();

  const [member, selectMember] = useState(null);
  const [fund, selectFund] = useState(null);
  const [memberName, setMemberName] = useState(null);
  const [fundName, setFundName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setIsLoading(true);
    fetchSingleFundWithMember(token, id)
      .then(({ data, status }) => {
        if (status === 200 && data?.status) {
          const formattedDate = (data?.record[0]?.date || "").split("-");
          setValue("amount", data?.record[0].amount);
          setValue(
            "date",
            data?.record[0].date ? new Date(formattedDate[0], formattedDate[1] - 1, formattedDate[2]) : new Date()
          );
          setMemberName(data?.record[0].fullname);
          setFundName(data?.record[0].fund);
          selectMember(data?.record[0].memberId);
          selectFund(data?.record[0].fundId);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        notification({ title: "Error", type: "danger", message: err.message });
        setIsLoading(false);
      });
  }, []);

  const onFormSubmit = (v) => {
    const payload = { ...v };
    updateFunMemberData(
      token,
      { ...v, date: moment(v.date).format("YYYY-MM-DD HH:mm:ss") },
      id
    )
      .then(({ data, status }) => {
        if (data?.status && status === 201)
          notification({
            type: "success",
            title: "updated",
            message: "Successfully updated!",
          });
        fetchAllMemberWithFund();
      })
      .catch((err) => {
        notification({ type: "danger", title: "Errors", message: err.message });
      });
    handleClose();
  };

  useEffect(() => {
    setValue("member", member);
    if (member) {
      clearErrors(["member"]);
    }
  }, [member]);

  useEffect(() => {
    setValue("fund", fund);
    if (fund) {
      clearErrors(["fund"]);
    }
  }, [fund]);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Update MemberFund Data
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-6 mt-3">
            <label htmlFor="date" className="text-label">
              Date
            </label>
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <div style={{ position: "relative" }}>
                  <DatePicker
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    className={"react-datePicker-component"}
                    id="date"
                    showYearDropdown
                    scrollableYearDropdown
                    dateFormat={"yyyy-MM-dd"}
                    minDate={new Date(1998, 0, 1)}
                    maxDate={new Date(2025, 4, 18)}
                  />
                  <label
                    htmlFor="date"
                    style={{ position: "absolute", right: 10, top: 3 }}>
                    <img src={CalIcon} height={25} id="date" />
                  </label>
                </div>
              )}
            />
            {errors?.date?.message && (
              <p style={{ color: "red", margin: "0" }}>
                {errors?.date?.message}
              </p>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <TextField
              label="Amount"
              placeholder="Enter Amount"
              customId="amount"
              type="text"
              register={register}
              errors={errors?.amount?.message}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <AutoCompleteMember
              selectMember={selectMember}
              register={register}
              control={control}
              initValue={memberName}
            />
            {errors?.member?.message && (
              <span style={{ color: "red" }}>{errors.member.message}</span>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <AutoCompleteFund
              selectFund={selectFund}
              register={register}
              control={control}
              initValue={fundName}
            />
            {errors?.fund?.message && (
              <span style={{ color: "red" }}>{errors.fund.message}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            {isLoading ? (
              <Loader height={"10px"} top={"20px"} />
            ) : (
              <SubmitButton label="Update Fund Data" />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateFundMemberForm;
