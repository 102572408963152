import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAuth } from "../../../Hooks/useAuth";
import { fetchMeetingParticipant } from "../../../API/zoom";
import TableActionCell from "../TableActionCell";
import List from "../List";
import { notification } from "../../../service/index";
import AbstractModal from "../../AbstractModal";
import LinkForm from "../../forms/zoom/LinkForm";
import { openModal } from "../../../reducers/modal";
import Loader from "../../common/Loader";
import NoRecordFound from "../../common/NoRecordFound";
import PaginationComponent from "../../common/ReactPaginate";

const ParticipantList = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [participantList, setParticipantList] = useState(null);
  const [show, setShow] = useState(false);
  const [participantInfo, setParticipatInfo] = useState({});

  // Fetch the meeting participants when the
  const fetchParticipants = () => {
    setIsLoading(true);
    fetchMeetingParticipant(id, token, page, 10)
      .then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          setParticipantList(res?.data?.fetchParticipants);
          setCount(res?.data?.totalCount);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          notification({
            type: "danger",
            title: err?.code,
            message: err?.response?.data?.message,
          });
        } else {
          notification({
            type: "danger",
            title: err?.code,
            message: err?.message,
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchParticipants();
  }, [page]);

  const handleClose = () => {
    dispatch(openModal({ modalType: "" }));
    setShow(!show);
  };

  const handleSelect = () => {};
  const handleSortingChange = () => {};

  const handleEdit = (id) => {
    setParticipatInfo({
      ...participantInfo,
      id: id,
      name: participantList.find((item) => item.id === id)?.participant_name,
    });
    setShow(!show);
    dispatch(openModal({ modalType: "link" }));
  };

  const columns = [
    {
      name: "participant_name",
      label: "Participant Name",
      width: 100,
      align: "left",
    },
    {
      name: "participant_email",
      label: "Participant Email",
      align: "center",
      width: 100,
    },
    {
      name: "participant_duration",
      label: "Participant Duration",
      align: "center",
      width: 100,
    },
    {
      name: "isParticipated",
      label: "IsPartiCipated",
      align: "center",
      width: 100,
    },
    {
      name: "capsuleUser",
      label: "CapsuleUser",
      align: "center",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left",
    },
  ];

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  const rows = (participantList || []).map((row) => {
    const action = (
      <TableActionCell
        actionsHeadline="Edit"
        hideEdit={false}
        editLabel="Edit"
        onEdit={handleEdit}
        hideDestroy={true}
        tableRow={{ rowId: row.id }}
        // onDestroy={handleDestroy}
      />
    );
    return {
      ...row,
      isParticipated: row.isParticipated ? "Yes" : "No",
      action,
    };
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!(participantList || []).length) {
    return <NoRecordFound />;
  }

  return (
    <div>
      <List
        // initialOrder={order}
        // initialDir={dir}
        rows={rows}
        loading={isLoading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Participants"
        listBodyheight={"70vh"}
        handleSelect={handleSelect}
        ReactPaginate={
          <PaginationComponent
            pageCount={Math.ceil(count / 10)}
            currentPage={page}
            handlePageChange={handlePageChange}
          />
        }
      />
      <AbstractModal
        show={show}
        handleClose={handleClose}
        Component={
          <LinkForm
            handleClose={handleClose}
            partcipantInfo={participantInfo}
            fetchParticipants={fetchParticipants}
          />
        }
      />
    </div>
  );
};

export default ParticipantList;
