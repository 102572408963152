import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    modalType: '',
    modalData: null,
}

const modal = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.modalType = action.payload.modalType;
            state.modalData = {...state.modalData, ...(action.payload.modalData || {})};
        },
        closeModal: (state) => {
            state.modalType = '';
            state.modalData = null;
        },
        updateModalData: (state, action) => {
            state.modalType = '';
            state.modalData = {...state.modalData, ...action.payload};
        }
    }
});

export const { openModal, closeModal, updateModalData } = modal.actions;

export default modal.reducer;