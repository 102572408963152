import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, TableSortLabel,
  Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Grid, Button
} from '@mui/material';
import { Delete as DeleteIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';



const propTypes = {
  initialOrder: PropTypes.string,
  initialDir: PropTypes.oneOf(['asc', 'desc']),
  rowPerPage: PropTypes.number,
  enableSelection: PropTypes.bool,
  enableSorting: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func,
  onSelected: PropTypes.func,
};

const defaultProps = {
  initialOrder: 'name',
  initialDir: 'desc',
  enableSelection: false,
  enableSorting: false,
  rowPerPage: 50,
  rows: [],
  columns: []
};

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  columns,
  onSortingChange }) {


  const createSortHandler = (columnDir, orderColumn) => {
    onSortingChange(columnDir, orderColumn);
  };
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"> */}
        {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        {/* </TableCell> */}
        {columns.map((column, key) => (
          <TableCell
            sx={{padding:"4px 10px"}}
            key={key}
            align={column.align ? column.align : 'left'}
            padding={column.padding ? column.padding : 'normal'}
            width={column.width ? column.width : 150}
            sortDirection={orderBy === column.name ? order : false}
            style={{ fontWeight: "bold" }}
          >
            {/* {console.log("column",column)} */}
            {column.label}
            {/* <TableSortLabel
              active={orderBy === column.name}
              direction={orderBy === column.name ? order : 'asc'}
              onClick={() => createSortHandler(orderBy === column.name ? order : 'desc', column.name)}
              style={{ fontWeight: "bold" }}
            >
              {orderBy === column.name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ numSelected, title, onSelectedDelete, selected, top, AddButton }) {
  const { collapsed } = useSelector(state => state.sidebar);
  let width = collapsed ? "94%" : "80%";

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{
        // position: "fixed",
        // width,
        // top: top?top:104,
        background: "white",
        zIndex: 1,
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      {AddButton}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const List = ({
  rows,
  columns,
  rowPerPage,
  onSortingChange,
  initialDir,
  initialOrder,
  title,
  onSelectedDelete,
  loading,
  listBodyheight,
  handleSelect,
  ReactPaginate,
  AddButton
}) => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowPerPage);
  // const handleSelect = (row) => {
  // setRowId(row?.id);
  // console.log("Row",row);
  // const selectedIndex = selected.findIndex(newRow => newRow.id === row.id);
  // if (selectedIndex < 0) {
  //   setSelected([...selected, row])
  // } else {
  //   selected.splice(selectedIndex, 1);
  //   setSelected([...selected]);
  // }
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    console.log("row", row);
    const index = selected.findIndex(newRow => newRow.id === row.id);
    return index > -1;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      !loading && rows && rows.length ? rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ) : [],
    [page, rowsPerPage, loading, rows],
  );

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <Box>
      <Paper>
        <EnhancedTableToolbar
          selected={selected}
          numSelected={selected.length}
          title={title}
          onSelectedDelete={onSelectedDelete}
          AddButton={AddButton}
        />
        <TableContainer style={{ height: '70vh', overflowY: 'auto' }}>
          <Table
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={initialDir}
              orderBy={initialOrder}
              onSelectAllClick={handleSelectAll}
              onSortingChange={onSortingChange}
              rowCount={visibleRows.length}
              columns={columns}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                // const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={() => handleSelect(row)}
                    role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    // selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={() => handleSelect(row)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    {
                      columns.map((column, i) =>
                        <TableCell
                          sx={{ lineHeight: "14px", padding: "10px 10px",fontSize:"13px" }}
                          component="th"
                          id={labelId}
                          scope="row"
                          key={i}
                          align={i === 0 ? "left" : "center"}
                        // height={15}
                        // sx={{height:"15"}}
                        >
                          {row[column.name]}
                        </TableCell>
                      )
                    }
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[50, 100, 200, 250]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        {ReactPaginate}
      </Paper>
    </Box>
  );
}

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default memo(List);