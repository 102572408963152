import React, { useEffect } from 'react'

const IdlePrompt = ({ remaining, handleStillHere, open, setRemaining, getRemainingTime }) => {

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    });

    return (
        <div
            className='modal handleInactiveModal'
            style={{
                display: open ? 'flex' : 'none'
            }}>
            <h3>Are you still here?</h3>
            <p>Logging out in {remaining} seconds</p>
            <button onClick={handleStillHere} className='btn btn-primary fw-semibold'>Im still here</button>
        </div>
    )
}

export default IdlePrompt