import { axiosClient } from './apiClient';
import { specificRequestInterceptor } from './apiClient';

export const uploadMemeberDoc = async (payload, accessToken) => {
    const response = await axiosClient.post("/dealum/createmember", payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}
export const uploadFundDoc = async (payload, accessToken) => {
    const response = await axiosClient.post("/dealum/createfund", payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}
export const getFundData = async (accessToken) => {
    // axiosClient.interceptors.request.eject(specificRequestInterceptor);
    const response = await axiosClient.get('/dealum/get-investmentdata', {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const createFundData = async (payload, accessToken) => {
    const response = await axiosClient.post('/dealum/create-investmentData', payload, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const getAllfundInvestmentData = async (accessToken, pageNumber, recordPerPage) => {
    // axiosClient.interceptors.request.eject(specificRequestInterceptor);
    const response = await axiosClient.get(`/dealum/get-factInvestmentData?pageNumber=${pageNumber}&recordPerPage=${recordPerPage}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}
export const deleteOneFactInvData = async (id, accessToken) => {
    const res = await axiosClient.delete(`/dealum/delete-factinv/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
    })
    return res;
}
export const getOneFactInvestmentData = async (id, accessToken) => {
    // axiosClient.interceptors.request.eject(specificRequestInterceptor);
    const response = await axiosClient.get(`/dealum/get-factinv/${id}`, {
        headers: { 'Authorization': `Bearer ${accessToken}` }
    })
    return response;
}
export const updateFactInvestmentData = async (id, payload, accessToken) => {
    const response = await axiosClient.put(`/dealum/update-factinv/${id}`, payload, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
    });
    return response;
}

export const fetchSimiliarCompanies = async (accessToken, search, { token }) => {
    const response = await axiosClient.get(`/dealum/search-similiarCompanies?search=${search}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        cancelToken: token
    });
    return response;
}


//Fund Related APIs
export const fetchAllfundData = async (accessToken, page, limit) => {
    const response = await axiosClient.get(`/fund/fetch-allFund?pageNo=${page}&limit=${limit}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const CreateFundData = async (accessToken, payload) => {
    const response = await axiosClient.post('/fund/create-fund', payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "application/json"
        }
    });
    return response;
}

export const getFundById = async (accessToken, id) => {
    const response = await axiosClient.get(`fund/fetch-fundById/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const UpdateFundData = async (accessToken, payload, id) => {
    const response = await axiosClient.put(`fund/update-fund/${id}`, payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "application/json"
        }
    });
    return response

}

// FundMember Related APIs

export const fetchAllfundWithMember = async (accessToken, pageNo, limit) => {
    const response = await axiosClient.get(`/fund/fetch-allMemberwithFund?pageNo=${pageNo}&limit=${limit}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const fetchSimiliarMember = async (accessToken, search, { token }) => {
    const response = await axiosClient.get(`/fund/fetch-SimilarMember?search=${search}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        cancelToken: token
    });
    return response;
}

export const fetchSimiliarFunds = async (accessToken, search, { token }) => {
    const response = await axiosClient.get(`/fund/fetch-fetchSimiliarfunds?search=${search}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        cancelToken: token
    });
    return response;
}

export const addFundToMember = async (accessToken, payload) => {
    const response = await axiosClient.post(`/fund/add-fundtoMember`, payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "application/json"
        }
    });
    return response;
}

export const fetchSingleFundWithMember = async (accessToken, id) => {
    const response = await axiosClient.get(`/fund/fetch-singleFundWithMemer/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const updateFunMemberData = async (accessToken, payload, id) => {
    const response = await axiosClient.put(`/fund/update-fundMember/${id}`, payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });
    return response;
};

export const deleteFunMemberData = async (accessToken, id) => {
    const res = await axiosClient.delete(`/fund/remove-funMember/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
    return res;
}

// Function for fetching all dealumMember
export const fetchAllDealumMember = async (accessToken, pageNumber, limit) => {
    const response = await axiosClient.get(`/dealum/fetch-dealumMembers?pageNumber=${pageNumber}&limit=${limit}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

// EndPoint call to link DealumMember to capsuleData
export const LinkDealumMemberTOCapsuleData = async (accessToken, payload) => {
    const response = await axiosClient.put('/dealum/link-dealumMemberwithCapsuleCRM', payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });
    return response;
}