import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack, Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({ records, setIndustries, initValue }) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(
    initValue === "N/A" || !initValue
      ? ["N/A"]
      : initValue?.split(",")?.map((item) => item.trim())
  );

  React.useEffect(() => {
    if (personName && records) {
      const ids = records
        ?.filter((item) => personName?.includes(item.name))
        ?.map(({ id }) => id);
      setIndustries(ids);
    }
  }, [personName]);

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel>Select Industries</InputLabel>
        <Select
          multiple
          value={personName}
          onChange={(e) => {
            setPersonName(e.target.value?.filter((item) => item !== "N/A"));
          }}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() => {
                    setPersonName(personName.filter((item) => item !== value));
                  }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
            </Stack>
          )}>
          {records?.map(({ name }) => (
            <MenuItem
              key={name}
              value={name}
              sx={{ justifyContent: "space-between" }}>
              {name}
              {personName.includes(name) ? <CheckIcon color="info" /> : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
