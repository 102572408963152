import axios from 'axios';

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    timeout: 10 * 1000,  // Set time out,
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
    },
});


export const specificRequestInterceptor = axiosClient.interceptors.request.use(
    (config) => {
        // Conditionally add additional data to the request config for a specific request
        if (config.url === '/dealum/createmember') {
            config.data.append("action", "create");
            config.data.append('entity', "memberDoc");
        } else if (config.url === '/dealum/createfund') {
            config.data.append("action", "create");
            config.data.append('entity', "fundDoc");
        } else if (config.url === '/dealum/create-investmentData') {
            config.data = { ...config.data, action: "create", entity: "Factinvestment" }
        }
        else if (config.url.startsWith('/dealum/update-factinv/')) {
            config.data = { ...config.data, action: "update", entity: "Factinvestment" }
        }
        else if (config.url === '/zoom/update-meeting') {
            config.data = { ...config.data, action: "update", entity: "Participants" }
        }
        else if (config.url === '/company/create-company') {
            config.data = { ...config.data, action: "create", entity: "Company" }
        } else if (config.url.startsWith('/company/update-company/')) {
            config.data = { ...config.data, action: "update", entity: "Company" }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

