import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm, Controller, get } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";

import TextField from "../../common/TextField";
import Loader from "../../common/Loader";
import SubmitButton from "../../common/SubmitButtom";
import SelectField from "../../common/SelectField";
import { getFundData, createFundData } from "../../../API/fund";
import { notification, dataDimDealType2, preventDefaultProps } from "../../../service/index";
import { useAuth } from "../../../Hooks/useAuth";
import SelectComponent from "../../select/SelectComponent";
import { CalIcon } from "../../../icons";
import Switch from "../../common/Switch";
import AutoCompleteFund from "../../select/AutoCompleteFund";
import AutoCompleteMemberWithInput from '../../select/AutoCompleteMemberWithInput';

const schema = yup
  .object({
    showFund: yup.boolean(),
    date: yup.date().required("date is required"),
    fund: yup.string().when("showFund", {
      is: true,
      then: () => yup.string().required("fund is required"),
      otherwise: () => yup.string().nullable(),
    }),
    firstName: yup.string().when("showFund", {
      is: true,
      then: () => yup.string().nullable(),
      otherwise: () => yup.string().required("firstname is required"),
    }),
    lastName: yup.string().when("showFund", {
      is: true,
      then: () => yup.string().nullable(),
      otherwise: () => yup.string().required("lastname is required"),
    }),
    dealType: yup.string().required("dealtype is required"),
    dealCatgType: yup.string().required("dealCatgType is required"),
    amount: yup.number().transform(value => Number.isNaN(value) ? null : value).required('Only numeric values are allowed').positive("Enter a positive Number"),
    round: yup.string().required("round is required"),
    company: yup.string().required("Company Name is required!"),
    dealType2: yup.string().when('dealType', {
      is: (value) => (value !== '3'),
      then: () => yup.string().nullable(),
      otherwise: () => yup.string().required("dealType2 is required"),
    }),
    PreMV: yup.number().transform(value => Number.isNaN(value) ? null : value).notRequired().positive('enter a positive number'),
    SizeOffering: yup.number().transform(value => Number.isNaN(value) ? null : value).notRequired().positive('enter a positive number'),
    GS_Deal_Memo: yup.boolean().required('GS Deal Memo field is required!'),
    LLC_Formed: yup.boolean().required('LLC_Formed is required!')
  })
  .required();

const CreateInvestmentForm = ({
  handleClose,
  fetchAllFundInvestmentData,
  setDataLoading,
}) => {
  const { token } = useAuth();
  const [fund, selectFund] = useState(null);
  const [showFund, setShowFund] = useState(false);
  let [records, setRecords] = useState(null);
  const [company, selectCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [gsDealMemo, setGsDealmemo] = useState(false);
  const [llcFormedValue, setLlcFormedValue] = useState(false);
  const [dealTypeValue, setDealTypeValue] = useState({ value: "", show: false });
  const [membeinfo, setMemberInfo] = useState({ id: null, firstName: "", lastName: "" });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
    watch
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getFundData(token)
      .then(({ data }) => {
        if (data && data?.status === true && data?.data) {
          setRecords(data?.data);
        }
      })
      .catch((err) => {
        notification({ title: "Error", type: "danger", message: err.message });
      });
  }, []);

  const onFormSubmit = (v) => {
    if (!company) return;
    setIsLoading(true);
    setDataLoading(true);
    const payload = {
      ...v,
      dealType: v.dealType === "N/A" ? null : v.dealType,
      dealCatgType: v.dealCatgType === "N/A" ? null : v.dealCatgType,
      firstName: showFund ? null : membeinfo.id ? null : v.firstName,
      lastName: showFund ? null : membeinfo.id ? null : v.lastName,
      fund: showFund ? getValues("fund") : null,
      dealType2: dealTypeValue.show ? v.dealType2 : null,
      round: v.round === 'N/A' ? null : v.round,
      memberId: showFund ? null : membeinfo.id
    };
    delete payload.showFund;
    createFundData(
      { ...payload, date: moment(v.date).format("YYYY-MM-DD HH:mm:ss") },
      token
    )
      .then(({ data }) => {
        if (data && data?.status === true) {
          notification({
            type: "success",
            title: "Created",
            message: "Successfully created!",
          });
          fetchAllFundInvestmentData();
          setDataLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setDataLoading(false);
        notification({ type: "danger", title: "Errors", message: err.message });
      });
    handleClose();
  };

  useEffect(() => {
    setValue("company", company);
    if (company) {
      clearErrors(["company"]);
    }
  }, [company]);

  useEffect(() => {
    setValue('firstName', membeinfo.firstName ? membeinfo.firstName : "");
    setValue('lastName', membeinfo.lastName ? membeinfo.lastName : "")
    if (membeinfo.firstName && membeinfo.lastName) {
      clearErrors(['firstName', 'lastName']);
    }
  }, [membeinfo]);

  useEffect(() => {
    setValue("fund", fund);
    if (fund) {
      clearErrors(["fund"]);
    }
  }, [fund]);

  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Create investment data
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-6 mt-3">
            <label htmlFor="date" className="text-label">
              Date
            </label>
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <div style={{ position: "relative" }}>
                  <DatePicker
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    className={"react-datePicker-component"}
                    id="date"
                    showYearDropdown
                    scrollableYearDropdown
                    dateFormat={"yyyy-MM-dd"}
                    minDate={new Date(1998, 0, 1)}
                    maxDate={new Date(2025, 4, 18)}
                  />
                  <label
                    htmlFor="date"
                    style={{ position: "absolute", right: 10, top: 3 }}>
                    <img src={CalIcon} height={25} id="date" />
                  </label>
                </div>
              )}
            />
            {errors?.date?.message && (
              <p style={{ color: "red", margin: "0" }}>
                {errors?.date?.message}
              </p>
            )}
          </div>
          <div className="col-md-6 mt-3 pt-2">
            <SelectField
              componentId={"dealCatgType"}
              register={register}
              label={"Deal Category Type"}
              errors={errors?.dealCatgType?.message}
              records={records?.dealCatgTypes}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Switch
              setShow={setShowFund}
              show={showFund}
              register={register}
              label="Add fund"
              customId={'showFund'}
            />
          </div>
        </div>
        {showFund ? (
          <div className="row">
            <div className="col-md-12">
              <AutoCompleteFund selectFund={selectFund} register={register} />
              {errors?.fund?.message && (
                <span style={{ color: "red" }}>{errors?.fund?.message}</span>
              )}
            </div>
          </div>
        ) : (
          <>
            <AutoCompleteMemberWithInput
              setMemberInfo={setMemberInfo}
              initValue={null}
              register={register}
            />
            <div className="row">
              <div className="col-md-6 mt-3">
                <TextField
                  label="First Name"
                  placeholder="Enter First Name"
                  customId="firstName"
                  type="text"
                  register={register}
                  errors={errors?.firstName?.message}
                />
              </div>
              <div className="col-md-6 mt-3">
                <TextField
                  label="Last Name"
                  placeholder="Enter Last Name"
                  customId="lastName"
                  type="text"
                  register={register}
                  errors={errors?.lastName?.message}
                />
              </div>
            </div>
            {!membeinfo.id && (getValues('firstName') !== '' || getValues('lastName') !== '') && <p style={{ color: "green", margin: "0px" }}>Typing in the first and last name fields without selecting from autocomplete will add a new member.</p>}
          </>
        )}

        <div className="row">
          <div className="col-md-6 mt-3">
            <TextField
              label="Amount"
              placeholder="Enter Amount"
              customId="amount"
              type="number"
              register={register}
              errors={errors?.amount?.message}
              {...preventDefaultProps}
            />
          </div>
          <div className="col-md-6 mt-4 pt-2">
            <SelectComponent
              selectCompany={selectCompany}
              register={register}
              control={control}
            />
            {errors?.company?.message && (
              <span style={{ color: "red" }}>{errors.company.message}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3 pt-3">
            <SelectField
              componentId={"round"}
              register={register}
              label={"Select Round"}
              errors={errors?.round?.message}
              records={records?.roundType}
            />
          </div>
          <div className="col-md-6 mt-3 pt-3">
            <SelectField
              componentId={"dealType"}
              register={register}
              label={"Deal Type"}
              errors={errors?.dealType?.message}
              records={records?.dealTypes}
              setDealTypeValue={setDealTypeValue}
              isDealTypeSelect={true}
            />
          </div>
        </div>
        <div className="row" style={{ height: "70px" }}>
          <div className="col-md-6 mt-3">
            <Link
              to={"/manage-companies?tab=add_new_company"}
              state={{ fromCreateFactForm: true }}
              style={{ padding: "0px 15px 15px 0px" }}>
              Create new company
            </Link>
          </div>
          {dealTypeValue?.show && (<div className="col-md-6 mt-3">
            <SelectField
              componentId={"dealType2"}
              register={register}
              label={"DealType2"}
              errors={errors?.dealType2?.message}
              records={dataDimDealType2}
              isDealType2Select={true}
              initValue={dealTypeValue.value}
              setDealTypeValue={setDealTypeValue}
            />
          </div>)}
        </div>
        <div className="row">
          <div className="col-md-6 pt-3">
            <TextField
              label="Pre Money Valuation"
              placeholder="Enter Pre Money Valuation"
              customId="PreMV"
              type="number"
              register={register}
              errors={errors?.PreMV?.message}
              {...preventDefaultProps}
            />
          </div>
          <div className="col-md-6 pt-3">
            <TextField
              label="Size of Offering"
              placeholder="Enter Size of Offering"
              customId="SizeOffering"
              type="number"
              register={register}
              errors={errors?.SizeOffering?.message}
              {...preventDefaultProps}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <Switch
              setShow={setGsDealmemo}
              show={gsDealMemo}
              register={register}
              label="GS Deal Memo"
              customId={'GS_Deal_Memo'}
            />
          </div>
          <div className="col-md-6 mt-3">
            <Switch
              setShow={setLlcFormedValue}
              show={llcFormedValue}
              register={register}
              label="LLC Formed"
              customId={'LLC_Formed'}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            {isLoading ? (
              <Loader height={"10px"} top={"20px"} />
            ) : (
              <SubmitButton label="Create Fund Data" />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateInvestmentForm;
