import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { FixedSizeList } from "react-window";
import { Button } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import axios from "axios";

import { fetchCapsuleCRMData } from "../../../API/zoom";
import { useAuth } from "../../../Hooks/useAuth";
import { notification } from "../../../service/index";
import Loader from "../../common/Loader";
import { LinkDealumMemberTOCapsuleData } from "../../../API/fund";

const LinkMemberToCapsuleForm = ({
  partcipantInfo,
  handleClose,
  fetchDealumAllMember,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [Id, setId] = useState(null);
  const { token } = useAuth();
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (debouncedSearch) {
      setIsLoading(true);
      fetchCapsuleCRMData(debouncedSearch, token, source)
        .then((res) => {
          if (res && res?.status === 200 && res?.data?.status) {
            setRows(res?.data?.records);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            setIsLoading(false);
            return;
          }
          setRows([]);
          notification({
            type: "danger",
            title: "Error",
            message: err.message,
          });
          setIsLoading(false);
        });
    } else {
      setRows([]);
    }
    return () => {
      source.cancel('request canceled!')
    }
  }, [debouncedSearch]);

  const handleRowClick = (index) => {
    setSelectedRow(index);
    setSearch(`${rows[index].fullName}(${rows[index].Email})`);
    setRows([]);
    setId(rows[index].id);
  };

  const handleChange = (e) => {
    if (selectedRow !== null) {
      setSearch("");
      setSelectedRow(null);
      setId(null);
    } else {
      setSearch(e.target.value);
    }
  };

  const handleLinkClick = () => {
    if (selectedRow === null || !Id) {
      notification({
        title: "Empty Rows",
        message: "Please Select a capsule user",
        type: "warning",
      });
    } else {
      LinkDealumMemberTOCapsuleData(token, {
        capsuleId: Id,
        dealumMemberId: partcipantInfo?.id,
      })
        .then((res) => {
          if (res.status === 201 && res?.data?.status) {
            handleClose();
            fetchDealumAllMember();
            notification({
              type: "success",
              title: "Success",
              message: `DealumnMember linked to capsuleCRM user`,
            });
          }
        })
        .catch((err) => {
          handleClose();
          notification({
            type: "danger",
            title: "Error",
            message: err.message,
          });
        });
    }
  };

  function renderRow(props) {
    let { index, style } = props;
    style = { ...style, height: 35 };
    const record = rows[index];
    const isSelected = selectedRow === index;

    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
        className={isSelected ? "list-style" : ""}>
        <ListItemButton
          onClick={() => handleRowClick(index)}
          style={{ boxShadow: "none" }}
          selected={isSelected}>
          <ListItemText primary={`${record.fullName}(${record.Email})`} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="on">
        <Typography variant="h5" sx={{ textAlign: "center", color: "#1346a3" }}>
          Edit{" "}
          <span style={{ fontWeight: "bold", color: "blue" }}>
            {`${partcipantInfo.First_Name} ${partcipantInfo.Last_Name}`}
          </span>{" "}
          link to capsule user
        </Typography>
        <TextField
          id="outlined-basic"
          label="Search in Capsule Records"
          variant="outlined"
          value={search}
          onChange={handleChange}
        />
      </Box>
      {isLoading ? (
        <Loader height={"60vh"} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: 400,
            maxWidth: 360,
            bgcolor: "background.paper",
          }}>
          <FixedSizeList
            height={350}
            width={480}
            itemSize={30}
            itemCount={rows.length}
            overscanCount={5}>
            {renderRow}
          </FixedSizeList>
        </Box>
      )}

      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-primary w-25 fw-bolder"
          onClick={handleLinkClick}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default LinkMemberToCapsuleForm;
