import React from "react";
import FactInvestMentList from './lists/InvestmentList/FundInvestmentList';
// import UsersList from "./lists/UsersList";

const HomePage = () => {
    return (
        <>
            <FactInvestMentList />
        </>
    )
}

export default HomePage;