import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';
import "antd/dist/reset.css"
import "react-datepicker/dist/react-datepicker.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-quill/dist/quill.snow.css';
import { useIdleTimer } from 'react-idle-timer';

import Routers from './Router';
import './App.css';
import { doLogin, doLogout } from './reducers/app';
import Loader from '../src/components/common/Loader';
import IdlePrompt from './components/common/IdlePrompt';

const timeout = 10 * 60 * 1000;
const promptBeforeIdle = 30 * 1000;
function App() {
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const [isLoggedIn, setLogin] = useState(false);
  const [isComponentReady, setComponentReady] = useState(false);
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.app);
  useEffect(() => {
    if (!token) {
      const _token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      if (_token && user) {
        dispatch(doLogin({ token: _token, user: JSON.parse(user) }));
        setLogin(true);
      }
      setComponentReady(true);
    } else {
      setLogin(true);
      setComponentReady(true);
    }
  }, [token]);

  const onIdle = () => {
    setOpen(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch(doLogout());
    setLogin(false);
  }

  const { getRemainingTime, activate } = useIdleTimer({
    timeout,
    onActive() {
      setOpen(false)
    },
    onIdle,
    onPrompt() {
      setOpen(true)
    },
    promptBeforeIdle,
    disabled: !isLoggedIn
  });

  return (
    <>
      {isComponentReady ?
        <>{open && (<IdlePrompt
          remaining={remaining}
          handleStillHere={() => { activate() }}
          open={open}
          getRemainingTime={getRemainingTime}
          setRemaining={setRemaining} />)}
          <ReactNotifications />
          <Routers auth={isLoggedIn} />
        </>
        : <Loader />}
    </>
  );
}

export default App;
