import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextField from "../common/TextField";
import SubmitButton from "../common/SubmitButtom";
import { resetPassword } from "../../API/user";
import { notification, status } from "../../service";
import CustomLink from "../common/CustomLink";
import Loader from "../common/Loader";

const schema = yup
  .object({
    password: yup
      .string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords don't match."),
  })
  .required();

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  let resetToken = "";
  if (pathname === "/resetPass") {
    resetToken = new URLSearchParams(search).get("token");
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = (v) => {
    setIsLoading(true);
    delete v.confirmPassword;
    resetPassword(resetToken, { newPassword: v.password })
      .then(({ status, data }) => {
        setIsLoading(false);
        if (status === 201 && data?.status) {
          notification({
            title: "Changed!",
            type: "success",
            message: data?.message,
          });
          navigate("/login");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 400 && !err?.response?.data?.status) {
          return notification({
            type: "danger",
            title: "Not found!",
            message: err?.response?.data?.message,
          });
        }
        notification({ type: "danger", title: "Error", message: err.message });
      });
  };

  return (
    <div className="login-signup-bg">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <div className="form-wrapper">
            <Typography
              variant="h4"
              style={{
                marginBottom: 10,
                textAlign: "center",
                color: "#1346a3",
              }}>
              Reset Password Form
            </Typography>
            <form className="signup-form" onSubmit={handleSubmit(onFormSubmit)}>
              <TextField
                label="New Password"
                placeholder="Enter Password"
                customId="password"
                type="password"
                register={register}
                errors={errors?.password?.message}
              />
              <TextField
                label="Confirm New Password"
                placeholder="Enter Confirm Password"
                customId="confirmPassword"
                type="password"
                register={register}
                errors={errors?.confirmPassword?.message}
              />
              <div>
                {isLoading ? (
                  <Loader height={52} />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Link to={"/login"}>Click here to login</Link>
                    <SubmitButton label={"Submit"} />
                  </div>
                )}
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
