import { axiosClient } from "./apiClient";

export const fetchCompanies = async (accessToken, pageNumber, pageSize) => {
    const companies = await axiosClient.get(`/company/fetch-companies?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return companies
}

export const fetchSectorState = async (accessToken) => {
    const response = await axiosClient.get('/company/fetch-sectorstate', {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const createCompany = async (accessToken, payload) => {
    const response = await axiosClient.post('/company/create-company', payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    });
    return response;
}

export const deleteCompany = async (accessToken, companyId) => {
    const response = await axiosClient.delete(`/company/delete-company/${companyId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const findCompanyById = async (accessToken, companyId) => {
    const response = await axiosClient.get(`/company/find-CompanybyId/${companyId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

export const updateCompany = async (accessToken, payload, companyId) => {
    const response = await axiosClient.put(`/company/update-company/${companyId}`, payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
    return response;
}
