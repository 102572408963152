import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";

import TextField from "../../common/TextField";
import Loader from "../../common/Loader";
import SubmitButton from "../../common/SubmitButtom";
import SelectField from "../../common/SelectField";
import Switch from "../../common/Switch";
import AutoCompleteFund from "../../select/AutoCompleteFund";
import { dataDimDealType2, dataDimDealType, notification, preventDefaultProps } from '../../../service/index'
import {
  getFundData,
  getOneFactInvestmentData,
  updateFactInvestmentData,
} from "../../../API/fund";
import { useAuth } from "../../../Hooks/useAuth";
import SelectComponent from "../../select/SelectComponent";
import { CalIcon } from "../../../icons";

const schema = yup
  .object({
    showFund: yup.boolean(),
    date: yup.date().required("required"),
    fund: yup.string().when("showFund", {
      is: true,
      then: () => yup.string().required("required"),
      otherwise: () => yup.string().nullable(),
    }),
    firstName: yup.string().when("showFund", {
      is: true,
      then: () => yup.string().nullable(),
      otherwise: () => yup.string().required("required"),
    }),
    lastName: yup.string().when("showFund", {
      is: true,
      then: () => yup.string().nullable(),
      otherwise: () => yup.string().required("required"),
    }),
    dealType: yup.string().required("required"),
    dealCatgType: yup.string().required("required"),
    amount: yup.number().transform(value => Number.isNaN(value) ? null : value).required("Only numeric values are allowed").positive("enter a positive number"),
    round: yup.string().required("round is required"),
    company: yup.string().required("Company Name is required!"),
    dealType2: yup.string().when('dealType', {
      is: (value) => value !== '3',
      then: () => yup.string().nullable(),
      otherwise: () => yup.string().required("required"),
    }),
    PreMV: yup.number().transform(value => Number.isNaN(value) ? null : value).notRequired().positive('Only numeric values are allowed'),
    SizeOffering: yup.number().transform(value => Number.isNaN(value) ? null : value).notRequired().positive('Only numeric values are allowed'),
    GS_Deal_Memo: yup.boolean().required('GS Deal Memo field is required!'),
    LLC_Formed: yup.boolean().required('LLC_Formed is required!')
  })
  .required();

const UpdateInvestMentForm = ({
  handleClose,
  id,
  fetchAllFundInvestmentData,
  setDataLoading,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fund, selectFund] = useState(null);
  const [fundName, setFundName] = useState(null);
  const [showFund, setShowFund] = useState(true);
  const { token } = useAuth();
  let [records, setRecords] = useState(null);
  const [company, selectCompany] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [gsDealMemo, setGsDealmemo] = useState(false);
  const [llcFormedValue, setLlcFormedValue] = useState(false);
  const [dealTypeValue, setDealTypeValue] = useState({ value: "", show: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    getFundData(token)
      .then(({ data }) => {
        if (data && data?.status === true && data?.data) {
          setRecords(data?.data);
        }
      })
      .catch((err) => {
        notification({
          type: "danger",
          title: "Failed",
          message: err.message,
        });
      });
  }, []);

  useEffect(() => {
    getOneFactInvestmentData(id, token)
      .then(
        ({
          data: {
            data: [value],
            status,
          },
        }) => {
          if (value && status) {
            const formattedDate = (value.fullDate || "")?.split('-');
            setValue("amount", value?.Amount);
            setValue("company", value?.CompanyName);
            setValue("firstName", value?.FirstName);
            setValue("lastName", value?.LastName);
            setValue("round", value?.RoundType || 'N/A');
            setValue("date", value.fullDate ? new Date(formattedDate[0], formattedDate[1] - 1, formattedDate[2]) : new Date());
            setValue("dealType", value?.DealType || "N/A");
            setValue("dealCatgType", value?.DealCatgKey || "N/A");
            setValue('dealType2', value?.DealType2 === 3 ? '' : value?.DealType2);
            setValue('PreMV', value?.PreMV);
            setValue('SizeOffering', value?.SizeOffering);
            setFundName(value?.fund || null);
            selectFund(value?.fundId);
            setCompanyName(value?.CompanyName || null);
            if (value?.companyId) {
              selectCompany(value?.companyId);
            }
            if (value?.FirstName && value?.LastName && !value?.fund && !value?.fundId) {
              setShowFund(false);
            }
            if (value.LLC_Formed) {
              setLlcFormedValue(true)
            }
            if (value.GS_Deal_Memo) {
              setGsDealmemo(true)
            }
            if ((dataDimDealType).find(item => item.id === value?.DealType)?.name === 'SubsequentClose') {
              setDealTypeValue(pre => ({ ...pre, show: true }))
            }
            setIsLoading(false);
          }
        }
      )
      .catch((err) => {
        notification({
          type: "danger",
          title: "Failed",
          message: err.message,
        });
        setIsLoading(false);
      });
  }, []);


  const onFormSubmit = (v) => {
    setDataLoading(true);
    if (!company) return;
    let payload = {
      ...v,
      dealType: v.dealType === "N/A" ? null : v.dealType,
      company,
      dealCatgType: v.dealCatgType === "N/A" ? null : v.dealCatgType,
      date: moment(v.date).format("YYYY-MM-DD HH:mm:ss"),
      firstName: showFund ? null : v.firstName,
      lastName: showFund ? null : v.lastName,
      fund: !showFund ? null : getValues("fund"),
      dealType2: !dealTypeValue.show ? null : v.dealType2,
      round: v.round === 'N/A' ? null : v.round
    };
    delete payload.showFund;
    updateFactInvestmentData(id, payload, token)
      .then(({ data }) => {
        if (data && data?.status) {
          notification({
            type: "success",
            title: "Updated!!",
            message: "SuccessFully updated!",
          });
          setDataLoading(false);
          handleClose();
          fetchAllFundInvestmentData();
        }
      })
      .catch((err) => {
        setDataLoading(false);
        notification({
          type: "danger",
          title: "Failed",
          message: err.message,
        });
        handleClose();
      });
  };

  useEffect(() => {
    setValue("company", company);
    if (company) {
      clearErrors(["company"]);
    }
  }, [company]);

  useEffect(() => {
    setValue("fund", fund);
    if (fund) {
      clearErrors(["fund"]);
    }
  }, [fund]);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Update fund Data
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-6 mt-3">
            <div>
              <label htmlFor="date" className="text-label">
                Date
              </label>
              <Controller
                control={control}
                name="date"
                render={({ field }) => (
                  <div style={{ position: "relative" }}>
                    <DatePicker
                      placeholderText="Select date"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      className={"react-datePicker-component"}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      id="date"
                      dateFormat={"yyyy-MM-dd"}
                      minDate={new Date(1998, 0, 1)}
                      maxDate={new Date(2025, 4, 18)}
                    />
                    <label
                      htmlFor="date"
                      style={{ position: "absolute", right: 10, top: 3 }}>
                      <img src={CalIcon} height={25} id="date" />
                    </label>
                  </div>
                )}
              />
              {errors?.date?.message && (
                <p style={{ color: "red", margin: "0" }}>
                  {errors?.date?.message}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6 mt-3 pt-2">
            <SelectField
              componentId={"dealCatgType"}
              register={register}
              label={"Deal Category Type"}
              errors={errors?.dealCatgType?.message}
              records={records?.dealCatgTypes}
              initValue={getValues("dealCatgType")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Switch
              show={showFund}
              setShow={setShowFund}
              register={register}
              label={'Add fund'}
              customId={'showFund'}
            />
          </div>
        </div>
        {showFund ? (
          <div className="row">
            <div className="col-md-12">
              <AutoCompleteFund selectFund={selectFund} initValue={fundName} />
              {errors?.fund?.message && (
                <span style={{ color: "red" }}>{errors?.fund?.message}</span>
              )}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6 mt-3">
              <TextField
                label="First Name"
                placeholder="Enter First Name"
                customId="firstName"
                type="text"
                register={register}
                errors={errors?.firstName?.message}
              />
            </div>
            <div className="col-md-6 mt-3">
              <TextField
                label="Last Name"
                placeholder="Enter Last Name"
                customId="lastName"
                type="text"
                register={register}
                errors={errors?.lastName?.message}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-6 mt-3">
            <TextField
              label="Amount"
              placeholder="Enter Amount"
              customId="amount"
              type="number"
              register={register}
              errors={errors?.amount?.message}
              {...preventDefaultProps}
            />
          </div>
          <div className="col-md-6 mt-3 pt-3">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SelectComponent
                selectCompany={selectCompany}
                register={register}
                control={control}
                initValue={companyName}
              />
              {errors?.company && (
                <span style={{ color: "red" }}>{errors?.company?.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3 pt-3">
            <SelectField
              componentId={"round"}
              register={register}
              label={"Select Round"}
              errors={errors?.round?.message}
              records={records?.roundType}
              initValue={getValues("round")}
            />
          </div>
          <div className="col-md-6 mt-3 pt-3">
            <SelectField
              componentId={"dealType"}
              register={register}
              label={"Deal Type"}
              errors={errors?.dealType?.message}
              records={dataDimDealType}
              initValue={getValues("dealType")}
              setDealTypeValue={setDealTypeValue}
              isDealTypeSelect={true}
            />
          </div>
        </div>
        {dealTypeValue.show &&
          (<div className="row">
            <div className="col-md-12 mt-3">
              <SelectField
                componentId={"dealType2"}
                register={register}
                label={"DealType2"}
                errors={errors?.dealType2?.message}
                records={dataDimDealType2}
                initValue={getValues("dealType2")}
                isDealType2Select={true}
                isDealtype2Update={true}
              />
            </div>
          </div>)
        }
        <div className="row">
          <div className="col-md-6 mt-3">
            <TextField
              label="Pre Money Valuation"
              placeholder="Enter pre money valuation"
              customId="PreMV"
              type="number"
              register={register}
              errors={errors?.PreMV?.message}
              {...preventDefaultProps}
            />
          </div>
          <div className="col-md-6 mt-3">
            <TextField
              label="Size of Offering"
              placeholder="Enter Size Of Offering"
              customId="SizeOffering"
              type="number"
              register={register}
              errors={errors?.SizeOffering?.message}
              {...preventDefaultProps}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <Switch
              setShow={setGsDealmemo}
              show={gsDealMemo}
              register={register}
              customId={'GS_Deal_Memo'}
              label={'GS Deal Memo'}
            />
          </div>
          <div className="col-md-6 mt-3">
            <Switch
              setShow={setLlcFormedValue}
              show={llcFormedValue}
              register={register}
              customId={'LLC_Formed'}
              label={'LLC Formed'}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <SubmitButton label="Update" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateInvestMentForm;
