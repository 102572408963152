import React from "react";


const TextField = ({ placeholder, label, customId, register, errors, ...restProps }) => {
    return (
        <div className="text_field_div">
            <label htmlFor={customId} className="text-label mb-1" style={{display: 'block',fontWeight:"400"}}>
                {label}
            </label>
            <input placeholder={placeholder} id={customId} {...restProps} {...register(customId)} className="text-field" />
            {errors && <span style={{color: 'red',marginBottom:"0px"}}>{errors}</span>}
        </div>
    )
}
export default TextField;