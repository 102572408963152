import React, { useState, useEffect } from "react";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectField = ({
  initValue,
  records,
  errors,
  options,
  register,
  componentId,
  name,
  // optionId,
  label,
  ...restProps
}) => {
  const { isDealTypeSelect, setDealTypeValue, isDealType2Select, isDealtype2Update } = restProps;
  const [value, setValue] = useState(isDealType2Select ? initValue !== 3 ? initValue : "" : initValue || "N/A");
  useEffect(() => {
    if (isDealTypeSelect) {
      if (records?.find(item => item['id'] === value)?.name === 'SubsequentClose') {
        setDealTypeValue(pre => ({ ...pre, show: true }))
      } else {
        setDealTypeValue(pre => ({ ...pre, show: false }));
      }
    }
    if (isDealType2Select && !isDealtype2Update) {
      setDealTypeValue(pre => ({ ...pre, value }))
    }
  }, [value]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);
  };
  return (
    <div style={{ width: "100%", paddingTop: "10px" }} className="custom-select">
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="demo-select-small-label">{label}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id={componentId}
          value={value}
          label={label}
          {...register(componentId)}
          onChange={handleChange}
          MenuProps={MenuProps}>
          {
            !isDealType2Select && (<MenuItem value={"N/A"} divider={true}>N/A</MenuItem>)
          }
          {(records || []).map((item, index) => {
            let isDivider = records[index]?.name?.split(" ")[0] === records[index + 1]?.name?.split(" ")[0] || index === (records || []).length - 1;
            return (<MenuItem value={item?.id || item} divider={!isDivider}>{item?.name || item}</MenuItem>)
          })}
        </Select>
      </FormControl>
      {errors && <p style={{ color: "red" }}>{errors}</p>}
    </div>
  );
};
export default SelectField;
