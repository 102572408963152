import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";

import TextField from "../../common/TextField";
import Loader from "../../common/Loader";
import SubmitButton from "../../common/SubmitButtom";
import { addFundToMember } from "../../../API/fund";
import { notification } from "../../../service/index";
import { useAuth } from "../../../Hooks/useAuth";
import AutoCompleteMember from "../../select/AutoCompleteMember";
import AutoCompleteFund from "../../select/AutoCompleteFund";
import { CalIcon } from "../../../icons";

const schema = yup
  .object({
    date: yup.date().required("required"),
    amount: yup.string().required("required"),
    fund: yup.string().required("required"),
    member: yup.string().required("Member Name is required!"),
  })
  .required();

const CreateFundMemberForm = ({ handleClose, fetchAllMemberWithFund }) => {
  const { token } = useAuth();

  const [member, selectMember] = useState(null);
  const [fund, selectFund] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = (v) => {
    setIsLoading(true);
    addFundToMember(token, {
      ...v,
      date: moment(v.date).format("YYYY-MM-DD HH:mm:ss"),
    })
      .then(({ data, status }) => {
        if (data?.status && status === 201)
          notification({
            type: "success",
            title: "Created",
            message: "Successfully created!",
          });
        fetchAllMemberWithFund();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notification({ type: "danger", title: "Errors", message: err.message });
      });
    handleClose();
  };

  useEffect(() => {
    setValue("member", member);
    if (member) {
      clearErrors(["member"]);
    }
  }, [member]);

  useEffect(() => {
    setValue("fund", fund);
    if (fund) {
      clearErrors(["fund"]);
    }
  }, [fund]);

  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginBottom: 10, textAlign: "center" }}>
        Create Member fund Data
      </Typography>
      <form className="py-3" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <div className="col-md-6 mt-3">
            <label htmlFor="date" className="text-label">
              Date
            </label>
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <div style={{ position: "relative" }}>
                  <DatePicker
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    className={"react-datePicker-component"}
                    id="date"
                    showYearDropdown
                    scrollableYearDropdown
                    dateFormat={"yyyy-MM-dd"}
                    minDate={new Date(1998, 0, 1)}
                    maxDate={new Date(2025, 4, 18)}
                  />
                  <label
                    htmlFor="date"
                    style={{ position: "absolute", right: 10, top: 3 }}>
                    <img src={CalIcon} height={25} id="date" />
                  </label>
                </div>
              )}
            />
            {errors?.date?.message && (
              <p style={{ color: "red", margin: "0" }}>
                {errors?.date?.message}
              </p>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <TextField
              label="Amount"
              placeholder="Enter Amount"
              customId="amount"
              type="text"
              register={register}
              errors={errors?.amount?.message}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <AutoCompleteMember
              selectMember={selectMember}
              register={register}
              control={control}
            />
            {errors?.member?.message && (
              <span style={{ color: "red" }}>{errors.member.message}</span>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <AutoCompleteFund
              selectFund={selectFund}
              register={register}
              control={control}
            />
            {errors?.fund?.message && (
              <span style={{ color: "red" }}>{errors.fund.message}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            {isLoading ? (
              <Loader height={"10px"} top={"20px"} />
            ) : (
              <SubmitButton label="Create Fund Data" />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateFundMemberForm;
