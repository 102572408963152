import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useDebounce } from 'use-debounce';

import { fetchSimiliarMember } from "../../API/fund";
import { useAuth } from "../../Hooks/useAuth";
import { notification } from "../../service/index";

const AutoCompleteMemberWithInput = ({ setMemberInfo, initValue, register }) => {
    const [search, setSearch] = useState("");
    const [debouncedSearch] = useDebounce(search, 200);
    const [records, setRecords] = useState([]);
    const [value, setValue] = React.useState(initValue || null);
    const { token } = useAuth();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (debouncedSearch) {
            fetchSimiliarMember(token, search, source)
                .then((res) => {
                    if (res?.status === 200 && res?.data?.status) {
                        setRecords(res?.data?.rows);
                    }
                })
                .catch((err) => {
                    if (axios.isCancel(err)) {
                        return;
                    }
                    setRecords([]);
                    notification({ title: "Error", type: "danger", message: err.message });
                });
        } else {
            setRecords([]);
        }
        return () => {
            source.cancel('request canceled!');
        }
    }, [debouncedSearch]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        let memberValue = records.find((item) => item?.fullName === newValue)
        if (newValue) {
            const valuetobeSplitted = (memberValue.fullName || "").split(" ")
            setMemberInfo({ id: memberValue.id, firstName: valuetobeSplitted[0], lastName: valuetobeSplitted[1] });
        } else {
            setMemberInfo({ id: null, firstName: "", lastName: "" })
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Autocomplete
                        value={value}
                        onChange={handleChange}
                        inputValue={search}
                        onInputChange={(event, newInputValue) => {
                            setSearch(newInputValue);
                        }}
                        freeSolo
                        id="free-solo-demo"
                        options={records.map((item) => item.fullName)}
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Select a member" />}
                    />
                </div>
            </div>
        </>
    )
}

export default AutoCompleteMemberWithInput;