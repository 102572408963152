import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AbstractModal from "./AbstractModal";
import ChangePasswordForm from "./forms/users/ChangePassword";
import { doLogout } from "../reducers/app";
import { notification } from "../service";
import { logOutUser } from "../API/user";
import { useAuth } from "../Hooks/useAuth";
import { HeaderIcon } from "../icons";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useAuth();

  const handleShowClick = () => {
    setShowMenu(false);
    setShow(!show);
  };

  const handleLogout = () => {
    logOutUser(token)
      .then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          dispatch(doLogout());
          notification({ message: "Logout Successfully!" });
          navigate("/login");
        }
      })
      .catch((err) => {
        notification({ message: err.message, type: "danger", title: "Error" });
      });
  };

  return (
    <div
      style={{
        width: "100%",
        background: "#fff",
        borderBottom: "2px solid #eee",
      }}
      className="d-flex justify-content-between align-items-center py-2 shadow-lg p-2">
      {/* <h2 style={{ color: "#70d370" }}>Golden Seeds</h2> */}
      <div>
        <img src={HeaderIcon} alt="Logo" height={50} />
      </div>
      <div>
        <div
          style={{
            width: 50,
            height: 50,
            borderRadius: 50,
            cursor: "pointer",
            backgroundImage: 'url("/images/profile.png")',
            marginRight: "10px",
          }}
          className="header-profile position-relative"
          onClick={() => setShowMenu(!showMenu)}>
          {showMenu && (
            <div className="position-absolute profile-menu">
              <span
                role="button"
                className="menu-btn mb-1"
                onClick={handleShowClick}>
                Change Password
              </span>
              <span
                role="button"
                className="menu-btn"
                onClick={() => handleLogout()}>
                Logout
              </span>
            </div>
          )}
          <AbstractModal
            show={show}
            handleClose={handleShowClick}
            Component={<ChangePasswordForm handleClose={handleShowClick} />}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
