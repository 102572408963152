import React, { useEffect, useState, useRef } from "react";
import { uploadFundDoc, uploadMemeberDoc } from "../API/fund";
import { notification } from "../service/index";
import { useAuth } from "../Hooks/useAuth";

const Dashboard = () => {
  const inputMemberRef = useRef(null);
  const inputFundRef = useRef(null);
  const { token } = useAuth();
  const handleMemberDocDrop = (e) => {
    e.preventDefault();
    handleMemberDocChange(e.dataTransfer.files[0]);
  };
  const handleMemberDocChange = (memberDoc) => {
    if (!memberDoc) return;
    const formData = new FormData();
    formData.append("doc", memberDoc);
    uploadMemeberDoc(formData, token)
      .then(({ data }) => {
        if (data && data?.status) {
          notification({
            type: "success",
            title: "Record successFully created!",
            message: data?.message,
          });
        }
      })
      .catch((err) => {
        notification({
          type: "danger",
          title: "Failed",
          message: err?.message,
        });
      });
  };
  const handleFundDocChange = (fundDoc) => {
    if (!fundDoc) return;
    const formData = new FormData();
    formData.append("doc", fundDoc);
    uploadFundDoc(formData, token)
      .then(({ data }) => {
        if (data && data?.status) {
          notification({
            type: "success",
            title: "Record successFully created!",
            message: data?.message,
          });
        }
      })
      .catch((err) => {
        notification({
          type: "danger",
          title: "Failed",
          message: err?.message,
        });
      });
  };
  const handleFundDocDrop = (e) => {
    e.preventDefault();
    handleFundDocChange(e.dataTransfer.files[0]);
  };

  return (
    <div className="docContainer">
      <div
        onClick={() => {
          inputMemberRef.current.click();
        }}
        onDrop={handleMemberDocDrop}
        onDragOver={(e) => {
          e.preventDefault();
        }}>
        <input
          ref={inputMemberRef}
          hidden
          type="file"
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={(e) => {
            handleMemberDocChange(e.target.files[0]);
            e.target.value = null;
          }}
        />
        <h1>Upload Member Doc</h1>
      </div>
      <div
        onClick={() => {
          inputFundRef.current.click();
        }}
        onDrop={handleFundDocDrop}
        onDragOver={(e) => {
          e.preventDefault();
        }}>
        <input
          ref={inputFundRef}
          type="file"
          hidden
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={(e) => {
            handleFundDocChange(e.target.files[0]);
            e.target.value = null;
          }}
        />
        <h1>Upload Fund Doc</h1>
      </div>
    </div>
  );
};

export default Dashboard;
