import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip, TableCell } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const propTypes = {
  hideEdit: PropTypes.bool,
  editLabel: PropTypes.string,
  onEdit: PropTypes.func,
  hideDestroy: PropTypes.bool,
  destroyLabel: PropTypes.string,
  destroyIcon: PropTypes.func,
  onDestroy: PropTypes.func,
  className: PropTypes.string,
  moreActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.func,
      action: PropTypes.func,
    })
  ),
};
const defaultProps = {
  hideEdit: false,
  editLabel: "Edit",
  onEdit() {},
  hideDestroy: false,
  destroyLabel: "Delete",
  destroyIcon: undefined,
  onDestroy() {},
  className: undefined,
  moreActions: undefined,
};

const TableActionsCell = ({
  hideEdit,
  editLabel,
  onEdit,
  hideDestroy,
  destroyLabel,
  destroyIcon,
  onDestroy,
  className,
  moreActions,
  ...restProps
}) => {
  // Method to handle edit
  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();

      onEdit(restProps.tableRow.rowId, restProps);
    },
    [restProps, onEdit]
  );

  // Method to handle destroy
  const handleDestroy = useCallback(
    (e) => {
      e.stopPropagation();

      onDestroy(restProps.tableRow.rowId, restProps);
    },
    [restProps, onDestroy]
  );

  // Method to handle destroy
  const handleMoreAction = useCallback(
    (e, action) => {
      e.stopPropagation();
      if (action) action(restProps.tableRow.rowId, restProps);
    },
    [restProps]
  );
  let slotProps = {
    popper: {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [17, -12],
          },
        },
      ],
    },
  };

  return (
    <TableCell className="action-cell d-flex align-items-center" {...restProps}>
      {!hideEdit && (
        <Tooltip title={editLabel} placement="left" slotProps={slotProps} sx={{fontSize:10}} style={{fontSize:"5px"}} >
          <IconButton onClick={handleEdit} aria-label={editLabel}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {moreActions?.map((actionItem) => (
        <Tooltip title={actionItem.label}>
          <IconButton
            onClick={(e) => handleMoreAction(e, actionItem.action)}
            aria-label={actionItem.label}>
            {!actionItem.icon && actionItem.label}
            {actionItem.icon && actionItem.icon}
          </IconButton>
        </Tooltip>
      ))}
      {!hideDestroy && (
        <Tooltip title={destroyLabel} placement="right" slotProps={slotProps}>
          <IconButton onClick={handleDestroy} aria-label={destroyLabel}>
            {destroyIcon && destroyIcon}
            {!destroyIcon && <DeleteIcon />}
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
};

TableActionsCell.propTypes = propTypes;
TableActionsCell.defaultProps = defaultProps;

export default memo(TableActionsCell);
