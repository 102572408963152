import React, { useMemo } from "react";
import ReactPaginate from "react-paginate";

const PaginationComponent = ({ pageCount, currentPage, handlePageChange }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ReactPaginate
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        containerClassName="pagination-container"
        previousLinkClassName="previosLinkClassName"
        nextLinkClassName="nextLinkClassName"
        activeClassName="activeClassName"
        disabledClassName="PaginationDishabled"
        forcePage={currentPage}
      />
    </div>
  );
};

export default PaginationComponent;
