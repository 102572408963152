import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
const AbstractModal = ({
  show,
  handleClose,
  Component,
  editComponent,
  heading,
}) => {
  const { modalType } = useSelector((state) => state.modal);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="position-relative">
          <span
            className="position-absolute"
            style={{ top: 2, right: 5, cursor: "pointer" }}
            onClick={handleClose}>
            <CloseIcon />
          </span>
          {/* <Modal.Title>
            {modalType === "second"
              ? heading
              : `${modalType === "edit" ? "Edit" : "Add"} ${heading}`}
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {modalType === "edit"
            ? editComponent
            : modalType === "create"
            ? Component
            : modalType === "link"
            ? Component
            : ""}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AbstractModal;
